import styled from "@emotion/styled";

export const CallToAction = styled.p`
  margin: 0 auto;

  width: 60%;
  a {
    color: var(--colors-site-lightAccent);
    cursor: pointer;

    &:hover {
      color: var(--colors-site-darkGray);
    }
  }

  @media screen and (max-width: 1100px) {
    width: 90%;
  }
`;

export const CaresPageWrapper = styled.div`
  width: var(--screen-width);
  padding: 75px 20px 0px 150px;
  text-align: center;

  h4 {
    padding: 20px 0px;
    width: 80%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    width: 100vw;
    padding: 20px;
  }
`;

export const CaresInfo = styled.div`
  border: 0.5px solid gray;
  border-top: none;
`;

export const CaresTile = styled.div`
  width: 400px;
  margin: 10px 0px;
  text-align: left;

  p {
    padding: 0px 10px 10px 15px;
  }

  h4 {
    text-align: left;
    margin: 0;
    width: 100%;
    padding: 10px 10px 0px 15px;
    font-size: 1.2em;
  }

  &:hover {
    border-left: 5px solid red;
  }

  box-shadow: var(--shadow-elevation-medium-local);
`;

export const CaresTileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;

export const FeaturedContainer = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 260px;
  overflow: hidden;
  border-bottom: 2px solid var(--colors-site-mainTheme);
`;

export const ImageList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    display: block;
    margin: 0px auto;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
