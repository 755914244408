import React from "react";

import { VideoTile, VideoTitleContainer } from "./youTubePostThumb.styles";

const YouTubePostThumb = ({ item }) => {
  // console.log("IM A YOUTUBE THUMB");
  return (
    <a
      key={item.etag}
      href={`https://www.youtube.com/watch?v=${item.id.videoId}`}
    >
      <VideoTile>
        <div>
          <img src={item.snippet.thumbnails.medium.url} width="100%" alt="" />
          <VideoTitleContainer>{item.snippet.title}</VideoTitleContainer>
        </div>
      </VideoTile>
    </a>
  );
};

export default YouTubePostThumb;
