import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }

  h3 {
    line-height: 1.5;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  max-height: 400px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const Paragraph = styled.div`
  justify-self: start;
  padding: 50px 0px 50px 10%;
  &.blue {
    margin-top: 50px;
    background-color: var(--colors-site-mainTheme);
  }
  p {
    width: 60%;
  }
`;
