import { Link } from "react-router-dom";
import React from "react";
import dayjs from "dayjs";

import { myunescape } from "../../utils/utility-functions";
import LogoContainer from "../../components/logo-container/logo-container.component";
import Spinner from "../../components/spinner/spinner.component";

import abcCares from "../../static/images/ABC_Cares_Crop_2.png";

import {
  CallToAction,
  CaresInfo,
  CaresPageWrapper,
  CaresTile,
  CaresTileWrapper,
  FeaturedContainer,
  ImageList,
  LogoWrapper,
} from "./abccarespage.styles";

const ABCCaresPage = ({ caresPosts }) => {
  const mappedCaresList = () => {
    // console.log("MAPPED LIST", caresPosts);
    const list = caresPosts.map((items) => {
      if (items.featured_image_urls_v2.large) {
        return (
          <div key={items.id}>
            <Link to={`${items.id}`}>
              <CaresTile>
                <FeaturedContainer>
                  <img src={items.featured_image_urls_v2.large[0]} />
                </FeaturedContainer>
                <CaresInfo>
                  <h4>{myunescape(items.title.rendered)}</h4>
                  <p>
                    {dayjs(`${items.date}`).format("M/DD/YYYY").toUpperCase()}
                  </p>
                </CaresInfo>
              </CaresTile>
            </Link>
          </div>
        );
      }
    });
    // console.log("list", list);
    return list.filter((el) => el !== undefined);
  };

  return (
    <>
      <CaresPageWrapper>
        <LogoWrapper>
          <LogoContainer
            disabled={true}
            className="centered"
            source={abcCares}
            alt="ABC Logo"
            widthValue="400px"
            heightValue="auto"
            margin="0"
          />
        </LogoWrapper>
        <h4>
          Together, We are Building a Greater Baltimore One Project, One
          Community, and One Career at a Time
        </h4>
        <br />
        <CallToAction>
          <strong>GOT GOOD DEEDS?</strong> We want to hear about them! Send us a
          brief write-up and photos of the charitable event or project and we
          will include in future magazines and on our ABCCares website.
          <br />
          Please send your material to{" "}
          <a target="_blank" href="mailto:mpusateri@abcbaltimore.org">
            Mandi Pusateri
          </a>
        </CallToAction>
        <ImageList>
          {caresPosts && caresPosts.length > 0 ? (
            <CaresTileWrapper>{mappedCaresList()}</CaresTileWrapper>
          ) : (
            <Spinner />
          )}
        </ImageList>
      </CaresPageWrapper>
    </>
  );
};

export default ABCCaresPage;
