import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Paragraph, Wrapper } from "./apprenticeshiptrustpage.styles";

const ApprenticeshipTrustPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Apprenticeship Trust</h1>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="key-dates">
        <h3>The Maryland Apprenticeship Training Fund Law and You</h3>
        <br />
        <strong>Did You Know…</strong>
        <ul>
          <li>
            All Maryland state-funded prevailing wage projects valued at
            $500,000 or more AND individual contractor / subcontractor contracts
            valued at $100,000 or more are affected by this law?
          </li>
          <li>
            25 cents per hour must be paid for ALL workers or apprentices on a
            project that are also covered by the applicable prevailing wage
            determination
          </li>
          <li>
            ABC Greater Baltimore has made it easy for you to comply with this
            law?
          </li>
        </ul>
        <br />
        <strong>How Does The Law Affect You?</strong>
        <p>
          For all projects advertised for bids or proposals after July 1, 2013,
          each contractor performing a state prevailing wage contract valued at
          more than $100,000 must pay 25 cents per hour for every covered worker
          on the project into the Maryland state fund or into an eligible
          state-approved apprenticeship program, including ABC's. The monies
          paid for this purpose can then be credited (or deducted) from the
          fringe benefit portion of the prevailing wage determination on the
          project, if there is one.{" "}
        </p>
        <strong>What Are The Penalties For Violations?</strong>
        <p>
          Contractors or subcontractors that do not pay the required fees are
          liable for fines at twice the amount of unpaid apprenticeship training
          contributions. Civil penalties of up to $1,000 for each employee and
          each falsified record can be levied for any person, firm or
          corporation that willfully falsified or omitted information in
          connection with prevailing wage records. In addition, the Secretary of
          the Department of Labor, Licensing and Regulation (DLLR) may file suit
          to require the contractor or subcontractor to pay the amount required
          including interest, reasonable counsel fees and court costs.
        </p>
        <strong>How Do You Comply and Select the Right Recipient?</strong>
        <p>
          When contractors are awarded a state-funded project, they will be
          required to register electronically with the DLLR. Once registered
          under the Certified Payroll Process, contractors will be asked to
          which apprenticeship program they want to pay the required 25 cents
          per hour.
        </p>
        <p>
          Select{" "}
          <strong>
            Associated Builders and Contractors Craft Training Trust
          </strong>{" "}
          as your contribution recipient. ABC will ensure funds received will be
          dedicated to providing quality training programs for the betterment of
          the merit construction industry.{" "}
          <strong>
            Contributions to the ABC Craft Training Trust can be sent to: 2101
            E. Biddle St. Suite 5000 Baltimore, MD 21213
          </strong>
          .
        </p>
      </Paragraph>
      <Link
        target="_blank"
        to="https://dev.abcbaltimore.org/wp-content/uploads/2023/05/MD-Apprenticeship-Training-Fund-Law.pptx"
      >
        <CustomButton newSiteButton>
          FAQ / How to Select a Sponsor & More
        </CustomButton>
      </Link>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default ApprenticeshipTrustPage;
