import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const SponsorContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
