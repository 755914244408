import React from "react";

import { TitleBlock, AccentLineMain, FooterBlock } from "../../App.styles";
import {
  Container,
  CardBlock,
  Card,
  CardImage,
  DualCard,
  DualCardImage,
} from "./saveMoneyPage.styles";

import saveMoney from "../../static/images/Save_Money.jpg";
import chesapeakeLogo from "../../static/images/Chesapeake_Logo.jpg";
import pennLogo from "../../static/images/Penn_Logo.jpg";
import lowesLogo from "../../static/images/Lowes_Logo.jpg";
import verizonLogo from "../../static/images/Verizon_Logo.jpg";
import fuelSavings from "../../static/images/Fuel_Savings.jpg";
import contractorsLogo from "../../static/images/Contractors_Logo.jpg";
import benecoLogo from "../../static/images/Beneco_Logo.jpg";
import staplesLogo from "../../static/images/Staples_Logo.jpg";

const SaveMoneyPage = ({ intViewportWidth }) => {
  return (
    <Container>
      <TitleBlock className="reverse">
        <div>
          <img src={saveMoney} />
        </div>
        <div>
          <h1 className="newSiteHeading">Save Money</h1>
          <p>
            ABC Greater Baltimore offers exclusive savings on essential business
            services to help members reduce costs and boost efficiency. See
            below for the complete list of savings for your company as well as
            member-only discounts for your employees!
          </p>
          <p>
            Contact <a href="mailto:bruce@abcgima.org">Bruce Talbot</a> for more
            information
          </p>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <CardBlock>
        <Card href="https://www.ceiwc.com/abc" target="_blank">
          Workers Comp
          <CardImage src={chesapeakeLogo} />
        </Card>
        <Card
          href="https://www.pennnationalinsurance.com/business/group-programs/abc-maryland/"
          target="_blank"
        >
          Property & Casualty
          <CardImage src={pennLogo} />
        </Card>
        <Card
          href="https://www.abc.org/Membership/Member-Discounts/Lowes_2023"
          target="_blank"
        >
          LOWES Pro
          <CardImage src={lowesLogo} />
        </Card>
        <Card
          href="https://www.abc.org/Membership/Member-Discounts/Verizon-Wireless"
          target="_blank"
        >
          Verizon
          <CardImage src={verizonLogo} />
        </Card>
        <Card href="https://www.abc.org/fuel" target="_blank">
          Fuel Savings Program
          <CardImage src={fuelSavings} />
        </Card>
        <DualCard>
          Prevaling Wage Solutions
          <a href="https://beneco.com/solutions-medical/" target="_blank">
            <DualCardImage src={benecoLogo} />
          </a>
          <a href="https://www.contractorsplan.com/" target="_blank">
            <DualCardImage src={contractorsLogo} />
          </a>
        </DualCard>
        <Card
          href="https://staples-p.allego.com/gO6sKxTs8aBh9xg"
          target="_blank"
        >
          Staples
          <CardImage src={staplesLogo} />
        </Card>
      </CardBlock>
      <div style={{ textAlign: "center" }}>
        <a
          href="https://www.abc.org/Membership/Member-Discounts"
          target="_blank"
        >
          SEE COMPLETE LIST OF ABC PROGRAM SAVINGS
        </a>
        <br />
        <a href="https://www.abc.org/employeesignup" target="_blank">
          Employee Discounts
        </a>
      </div>
      <FooterBlock>
        <h2 className="newSiteHeading">
          ABC Members Save Money on Critical Business Services
        </h2>
      </FooterBlock>
    </Container>
  );
};

export default SaveMoneyPage;
