import React from "react";
import { Link } from "react-router-dom";

import {
  AccentLineMain,
  AccentLineThin,
  FooterBlock,
  TitleBlock,
} from "../../App.styles";

import Page from "../../components/page/page.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import { Paragraph, Wrapper } from "./pdpcurriculumpage.styles";

const PDPCurriculumPage = () => {
  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">PDP Curriculum</h1>
        </div>
        <div>
          <p>
            The ABC Young Leaders Professional Development Program is a six
            month program designed to provide a series of educational seminars,
            network opportunities, mentoring and community service projects for
            the developing young professional. Below you will find detailed
            information on the curriculum of the program.{" "}
          </p>
          <Link to="https://mydigitalpublication.com/publication/?m=14697&i=821647&p=32&ver=html5">
            Read about our Professional Development Program
          </Link>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <h2>Sessions</h2>
      <Paragraph>
        <h3>Session 1</h3>
        <br />
        <p>
          Harrison Exam, Each student will take the online Harrison Assessment
          and will set up a one-on-one session in January with Harrison
          professional Ken Zeigler of Wellspring Advantage to review the
          individual test results.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 2, Wednesday, January 8th, 2025; ABC Greater Baltimore, 3 pm
          to 5 pm
        </h3>
        <br />
        <p>
          <strong>
            Program Orientation, Mentor Mix & Mingle, and Happy Hour,
          </strong>{" "}
          Students and mentors will be introduced to the program where the
          guidelines and requirements will be laid out for the year. Following
          the brief meeting there will be timed sessions for each student to
          'speed network' their way through meeting their mentors & fellow
          student groups. An informal network to follow.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 3, Thursday, February 6th, 2025; ABC Greater Baltimore, 8 am
          to 10 am
        </h3>
        <br />
        <p>
          <strong>
            Leadership Foundations:{" "}
            <em>Uncovering your identity as a leader</em>,
          </strong>{" "}
          In this leadership session, led by Stephanie Gnau of{" "}
          <a target="_blank" href="https://www.theleadershipacademy.info/">
            The Leadership Academy
          </a>
          , you'll embark on a journey to uncover your unique identity as a
          leader by delving into your core values and principles. You'll craft a
          personalized leadership philosophy that reflects your authentic self
          and guides your decision-making, which means consistency and building
          trust with your team. You'll also learn the importance of starting
          with the “why” when leading your team. This will connect your team to
          the purpose, ensuring that actions and goals align, and that your team
          follows through on “asks.”
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 4, Thursday, February 27th, 2025; ABC Greater Baltimore, 8 am
          to 10 am
        </h3>
        <br />
        <p>
          <strong>Career Development Reports,</strong> We will welcome back Ken
          Zeigler to discuss a newly introduced portion of the Harrison
          Assessments, Career Development Reports. These reports are
          personalized to each student and will focus on certain traits that
          show a need for development. This class allows each student and mentor
          to collaborate on growth and development strategies.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 5, Thursday, March 27th, 2025; ABC Greater Baltimore, 8 am to
          10 am
        </h3>
        <br />
        <p>
          <strong>Negotiating Skills,</strong> Presented by David Kelly of
          Growth Solutions. In this informative session participants will learn
          industry tricks on how to negotiate specific to the construction
          trades as well as in tough life situations in general. You are sure to
          leave this session feeling empowered and convident to take on those
          tough conversations.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>Session 6, March/April 2025</h3>
        <br />
        <p>
          <strong>Class Organized Social Event,</strong> The class will organize
          an outing for students and mentors. It could be anything from Axe
          Throwing to Go Cart Races to Clay Pigeon Shooting , etc… The group of
          students are expected to initiate the plans amongst themselves and
          make arrangements for the event (the event is funded through the PDP
          tuitions).
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 7, Thursday, April 24th, 2025; ABC Greater Baltimore, 8 am to
          10 am
        </h3>
        <br />
        <p>
          <strong>
            Communicating with Emotional Intelligence:{" "}
            <em>Having the right conversations for positive outcomes</em>,
          </strong>{" "}
          In this leadership session, we welcome back Stephanie Gnau of{" "}
          <a target="_blank" href="https://www.theleadershipacademy.info/">
            The Leadership Academy
          </a>
          , to help you learn strategies for navigating challenging
          conversations with confidence and fostering positive outcomes. We'll
          explore techniques for delivering constructive feedback effectively
          and addressing difficult issues with empathy and clarity. By mastering
          these skills, you'll enhance your ability to build strong
          relationships and drive meaningful change within your team.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>Session 8, April/May 2025</h3>
        <br />
        <p>
          <strong>One-on-One with Industry Leader,</strong> Students find time
          on their own schedule to meet one-on-one with their appointed mentors.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 9, Thursday, May 22nd, 2025; ABC Greater Baltimore, 3 pm to 5
          pm
        </h3>
        <br />
        <p>
          <strong>Young Leader Panel Discussion,</strong> The students and
          mentors will attend the Young Leader Committee hosted panel discussion
          & Network. The panel topic and panelists will vary based on timely
          content decided on upon the committee. Topics may include Construction
          Technology, the Lifecycle of a given project (panel featuring owner,
          architect & GC) and other relevant industry topics. A happy hour and
          network to follow.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>
          Session 10, Thursday, June 5th, 2025; ABC Greater Baltimore, 8 am to
          10 am
        </h3>
        <br />
        <p>
          <strong>
            Connect & Thrive:{" "}
            <em>
              Building relationships so your team is productive, motivated and
              engaged
            </em>
            ,
          </strong>{" "}
          In this leadership session, Stephanie Gnau of{" "}
          <a target="_blank" href="https://www.theleadershipacademy.info/">
            The Leadership Academy
          </a>{" "}
          joins us again , and you'll discover how to build strong, trust-based
          relationships that drive team productivity and engagement. We'll
          explore strategies for fostering a positive team culture, motivating
          members, and addressing individual needs to keep everyone inspired and
          committed. By enhancing your relational skills, you'll create an
          environment where your team thrives and performs at its best.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>Community Service Project, April/May 2025</h3>
        <br />
        <p>
          To complete this program, each student will need to participate in a
          community service outreach at the Baltimore City Ronald McDonald
          House. The coordination of service will be done by the students based
          on their schedules and availability.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <Paragraph>
        <h3>Wednesday, June 18th 2025; Location TBD</h3>
        <br />
        <p>
          <strong>Graduation Dinner & Awards,</strong> Students, mentors and a
          guest of their choosing are invited to attend this dinner celebration.
          Certificates of completion will be given out to students, and we will
          honor our top-level graduate.
        </p>
        <br />
        <AccentLineThin />
      </Paragraph>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default PDPCurriculumPage;
