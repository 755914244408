const proSponsorLogoData = [
  {
    id: 1,
    companyName: "Bmore Technology",
    companyUrl: "https://www.bmoretechnology.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/BmoreTech_Logo.png"),
  },
  {
    id: 2,
    companyName: "Chesapeake Employers' Insurance Company",
    companyUrl: "https://www.ceiwc.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/CEIC_Logo.png"),
  },
  {
    id: 3,
    companyName: "The Contractors Plan",
    companyUrl: "https://www.contractorsplan.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/Contractors_Plan_Logo.png"),
  },
  {
    id: 4,
    companyName: "Penn National Insurance",
    companyUrl: "https://www.pennnationalinsurance.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/PNI_Logo.png"),
  },
  {
    id: 5,
    companyName: "Skillforce, IncTrade .",
    companyUrl: "https://www.skillforce.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/Skillforce_Logo.png"),
  },
  {
    id: 6,
    companyName: "Trade Scouts",
    companyUrl: "https://www.tradescouts.com/",
    sponsorLevel: "elite",
    image: require("../static/images/pro_sponsors/Tradescouts_Logo.jpg"),
  },
  {
    id: 7,
    companyName: "Gray & Son",
    companyUrl: "https://www.graynson.com/",
    sponsorLevel: "executive",
    image: require("../static/images/pro_sponsors/GrayNSon_Logo.png"),
  },
  {
    id: 8,
    companyName: "Turner Construction Company",
    companyUrl: "https://www.turnerconstruction.com/",
    sponsorLevel: "executive",
    image: require("../static/images/pro_sponsors/Turner_Logo.png"),
  },
  {
    id: 9,
    companyName: "Aerotek, Inc.",
    companyUrl: "https://www.aerotek.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Aerotek_Logo.png"),
  },
  {
    id: 10,
    companyName: "Diversified Safety Services",
    companyUrl: "https://www.getdsservices.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Diversified_Logo.png"),
  },
  {
    id: 11,
    companyName: "East Coast Electric, Inc.",
    companyUrl: "https://www.eastcoastelectric.net/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/East_Coast_Logo.png"),
  },
  {
    id: 12,
    companyName: "ECRS - East Coast Rigging & Scaffolding",
    companyUrl: "https://www.eastcoastrigging.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/ECRS_Logo.png"),
  },
  {
    id: 13,
    companyName: "EMJAY Engineering & Construction Co., Inc.",
    companyUrl: "https://www.emjaycons.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Emjay_Logo.png"),
  },
  {
    id: 14,
    companyName: "Harkins Builders, Inc.",
    companyUrl: "https://www.harkinsbuilders.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Harkins_Logo.png"),
  },
  {
    id: 15,
    companyName: "HUB International Mid-Atlantic/Surety Services, LLC",
    companyUrl: "https://www.hubinternational.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Hub_Logo.png"),
  },
  {
    id: 16,
    companyName: "Lawrence Law, LLC",
    companyUrl: "https://www.lawrencelawllc.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Lawrence_Law_Logo.png"),
  },
  {
    id: 17,
    companyName: "Lewis Contractors",
    companyUrl: "https://www.lewis-contractors.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Lewis_Logo.png"),
  },
  {
    id: 18,
    companyName: "Milwaukee Tool Company",
    companyUrl: "",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Milwaukee_Logo.png"),
  },
  {
    id: 19,
    companyName: "Mullan Contracting Company",
    companyUrl: "https://www.mullancontr.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Mullan_Logo.png"),
  },
  {
    id: 20,
    companyName: "Southway Builders, Inc.",
    companyUrl: "",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Southway_Logo.png"),
  },
  {
    id: 21,
    companyName: "Xpertechs",
    companyUrl: "https://xpertechs.com/",
    sponsorLevel: "corporate",
    image: require("../static/images/pro_sponsors/Xpertech_Logo.png"),
  },
  {
    id: 22,
    companyName: "Alliance Exterior Construction, Inc.",
    companyUrl: "https://www.allianceexterior.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Alliance_Logo.png"),
  },
  {
    id: 23,
    companyName: "Benchmark Construction Co., Inc.",
    companyUrl: "https://www.benchmarkgc.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Benchmark_Logo.png"),
  },
  {
    id: 24,
    companyName: "Capital Bank, N.A.",
    companyUrl: "https://www.capitalbankmd.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Capital_Bank_Logo.png"),
  },
  {
    id: 25,
    companyName: "First Financial Federal Credit Union",
    companyUrl: "https://www.firstfinancial.org/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/FFFCU_Logo.png"),
  },
  {
    id: 26,
    companyName:
      "XpertechsHMS Insurance Associates, a Marsh & McLennan Agency LLC company",
    companyUrl:
      "https://www.marshmma.com/us/locations/maryland/hunt-valley.html",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/HMS_Logo.png"),
  },
  {
    id: 27,
    companyName: "Merritt Construction Services",
    companyUrl: "https://www.merrittconstruction.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Merritt_Logo.png"),
  },
  {
    id: 28,
    companyName: "North Point Builders of Maryland, LLC",
    companyUrl: "https://www.northpointbuilders.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Northpoint_Logo.png"),
  },
  {
    id: 34,
    companyName: "Perry Moving and Storage",
    companyUrl: "https://perrymoving.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Perry_Logo.jpg"),
  },
  {
    id: 29,
    companyName: "RCM&D",
    companyUrl: "https://www.rcmd.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/RCM&D_Logo.png"),
  },
  {
    id: 30,
    companyName: "Sandy Spring Bank",
    companyUrl: "https://www.sandyspringbank.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Sandy_Spring_Bank_Logo.png"),
  },
  {
    id: 31,
    companyName: "Tegeler Construction & Supply",
    companyUrl: "https://www.tegelercs.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Tegeler_Logo.png"),
  },
  {
    id: 32,
    companyName: "Warwick Contracting",
    companyUrl: "",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Warwick_Logo.png"),
  },
  {
    id: 33,
    companyName: "Wohlsen Construction Company",
    companyUrl: "https://www.wohlsenconstruction.com/",
    sponsorLevel: "professional",
    image: require("../static/images/pro_sponsors/Wohlsen_Logo.png"),
  },
];

export default proSponsorLogoData;
