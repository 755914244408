import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  InnerDiv,
  FooterBlock,
  TwoColumnGrid,
  AccentLineMain,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Wrapper, CardRow, Card, Arrow } from "./siteinspectionspage.styles";

const SiteInspectionsPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <h1 className="newSiteHeading">Jonsite Safety Inspections</h1>
        </div>
        <div>
          <p>
            Construction safety inspections are the most effective means of
            identifying hazardous conditions at the worksite. Our inspections
            can help to identify OSHA violations and risk exposures before they
            damage your company's reputation or injure employees.
          </p>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <InnerDiv>
        <h2>
          Within 24 hours of an inspection, you will receive a report that will
          provide you with:
        </h2>
        <CardRow>
          <Card>
            <Arrow>1</Arrow>
            <br />
            <h3 className="white-text">Documentation</h3>
            <br />
            <p className="white-text">
              showing monthly audits are being performed
            </p>
          </Card>
          <Card>
            <Arrow>2</Arrow>
            <br />
            <h3 className="white-text">Descriptions</h3>
            <br />
            <p className="white-text">detailing any issues found</p>
          </Card>
          <Card>
            <Arrow>3</Arrow>
            <br />
            <h3 className="white-text">Pictures</h3>
            <br />
            <p className="white-text">to provide proof of any non-compliance</p>
          </Card>
        </CardRow>
        <br />
        <h2>Contact Us Today to Learn More About Safety Services</h2>
        <br />
        <a target="_blank" href="mailto:rhughes@abcbaltimore.org">
          <CustomButton newSiteButton>Contact ABC Safety Services</CustomButton>
        </a>
      </InnerDiv>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Increase productivity. Improve your safety record.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default SiteInspectionsPage;
