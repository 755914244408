import React from "react";

import { myunescape } from "../../utils/utility-functions";

import {
  Background,
  BlogLink,
  BlogTile,
  BlogTitleContainer,
} from "./blogPostThumb.styles";

const BlogPostThumb = ({ item }) => {
  // console.log("BLOG ITMEM", item);
  return (
    <BlogLink key={item.id} to={`news/${item.id}`}>
      <BlogTile>
        <div>
          {item.fimg_url ? <img src={item.fimg_url} alt="" /> : <Background />}
          <BlogTitleContainer>
            {myunescape(item.title.rendered)}
          </BlogTitleContainer>
        </div>
      </BlogTile>
    </BlogLink>
  );
};

export default BlogPostThumb;
