import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ImageCard = styled.div`
  width: 100%;
  padding: 0px 10%;
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;

  &.blue {
    padding: 20px 10%;
    background-color: var(--colors-site-mainTheme);
    p {
      color: var(--colors-site-white);
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding: 0 5%;
  }
`;

export const Image = styled.div`
  max-width: 350px;
  flex: 1 0 350px;
  align-self: start;

  img {
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  @media screen and (max-width: 850px) {
    max-width: 350px;
    flex: 0 0 200px;
  }
`;
