import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./academiespage.styles";

const AcademiesPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const superintendentsEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Superintendents Academy")
  );
  const projectManagementEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Project Management Academy")
  );
  const constructionAdministrationEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Construction Asministration")
  );
  const relationshipEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Relationship &#038; Business")
  );
  const crewLeadershipEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Crew Leadership")
  );
  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Academies</h1>
        </div>
        <div>
          <p>
            ABC Greater Baltimore offers a range of academies designed to
            advance skills in key areas of the construction industry, including
            leadership, project management, financial administration, and
            business development. These programs provide comprehensive training
            to help professionals excel in planning, budgeting, compliance, and
            building strong industry relationships. Whether you’re a crew
            leader, project manager, or business development professional, these
            academies equip you with the tools to succeed and grow in your
            career.
          </p>
          <HashLink onClick={() => scrollToSection("superintendents")}>
            Superintendents Academy
          </HashLink>
          <HashLink onClick={() => scrollToSection("project-management")}>
            Project Management Academy
          </HashLink>
          <HashLink
            onClick={() => scrollToSection("construction-administration")}
          >
            Construction Administration Academy
          </HashLink>
          <HashLink onClick={() => scrollToSection("relationship")}>
            Relationship & Business Development Academy
          </HashLink>
          <HashLink onClick={() => scrollToSection("crew-leadership")}>
            Crew Leadership Training
          </HashLink>
          <br />
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="superintendents">
        <h3>Superintendents Academy</h3>
        <p>This is a 16 hour series of courses that will cover:</p>
        <ul>
          <li>PLANNING & SCHEDULING</li>
          <li>PROJECT COST CONTROL</li>
          <li>CONSTRUCTION PRODUCTIVITY</li>
          <li>CREW LEADERSHIP</li>
          <li>PROJECT CLOSE OUT</li>
        </ul>
        {superintendentsEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={superintendentsEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${superintendentsEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {superintendentsEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Superintendents+Academy%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="project-management">
        <h3>Project Management Academy</h3>
        <p>This is a 15 hour series of courses that will cover:</p>
        <ul>
          <li>PRECONSTRUCTION & OPERATIONS BUDGET MANAGEMENT</li>
          <li>PLANNING & SCHEDULING</li>
          <li>CONSTRUCTION CLAIMS</li>
          <li>PROJECT CLOSE OUT</li>
        </ul>
        {projectManagementEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={projectManagementEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${projectManagementEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {projectManagementEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22project+management+academy%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="construction-administration">
        <h3>Construction Administration & Accounting Academy</h3>
        <p>This is a 12 hour series of courses that will cover:</p>
        <ul>
          <li>FINANCIAL REPORTS USED IN CONSTRUCTION</li>
          <li>FRAUD PREVENTION</li>
          <li>CONTRACTOR COMPLIANCE & RECORDKEEPING</li>
          <li>JOB COSTING</li>
          <li>RECEIVABLES & CASH MANAGEMENT</li>
        </ul>
        {constructionAdministrationEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={constructionAdministrationEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(
                `${constructionAdministrationEvents[0].start_date}`
              ).format("MMM D @ h:mma")}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {constructionAdministrationEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Construction+Administration"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="relationship">
        <h3>Relationship & Business Development Academy</h3>
        <p>
          Who should come: Business Development professionals from commercial
          construction primes doing business with private and public owners;
          sub-contractors performing commercial work; and members with services
          related to the commercial building industry.
        </p>
        <ul>
          <li>DEVELOPING THE “RIGHT” RELATIONSHIPS AND OPPORTUNITIES</li>
          <li>CONDUCTING PROFESSIONAL MEETINGS THAT PRODUCE RESULTS</li>
          <li>BUILDING A RELATIONSHIP AND VALUE OVER TIME</li>
        </ul>
        {relationshipEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={relationshipEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${relationshipEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {relationshipEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Relationship+Academy"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="crew-leadership">
        <h3>Crew Leadership Training</h3>
        <p>
          While this course has been designed to assist the recently promoted
          crew leader, it is beneficial for anyone in management. The course
          covers basic leadership skills and explains different leadership
          styles, communication, delegating, and problem solving. Job-site
          safety and the crew leader’s role in safety are also discussed. This
          edition goes into detail on project planning, scheduling, and
          estimating with new performance tasks to assist the learning process.
        </p>
        {crewLeadershipEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={crewLeadershipEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${crewLeadershipEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {crewLeadershipEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Crew+Leadership+Training"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default AcademiesPage;
