import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  InnerDiv,
  FooterBlock,
  TwoColumnGrid,
  AccentLineMain,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Wrapper, CardRow, Card, Arrow } from "./newhirepage.styles";

const NewHirePage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <h1 className="newSiteHeading">New Hire Safety Orientation</h1>
        </div>
        <div>
          <p>
            ABC developed the Safety Orientation Template to provide a
            consistent, impactful safety orientation program to employees. This
            customizable template will guide you th
          </p>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <InnerDiv>
        <h2>According to recent industry studies</h2>
        <br />
        <p>
          A new hire safety orientation is the most impactful measure an
          employer can take to reduce job site accidents.
        </p>
        <CardRow>
          <Card>
            <h3 className="white-text">Facilitator Guide</h3>
            <br />
            <p className="white-text">
              Designed for any safety professional who is tasked with delivering
              safety orientation programs.
            </p>
            <Link to="/other-resources">
              <CustomButton newSiteButton>Donwload</CustomButton>
            </Link>
          </Card>
          <Card>
            <h3 className="white-text">Participant Guide</h3>
            <br />
            <p className="white-text">
              Allows facilitators to include important information that will be
              covered during the orientation training.
            </p>
            <Link to="/other-resources">
              <CustomButton newSiteButton>Donwload</CustomButton>
            </Link>
          </Card>
          <Card>
            <h3 className="white-text">PowerPoint Template</h3>
            <br />
            <p className="white-text">
              22 topics & corresponding slides that will help you to develop and
              present at least 200 minutes of contents.
            </p>
            <Link to="/other-resources">
              <CustomButton newSiteButton>Donwload</CustomButton>
            </Link>
          </Card>
        </CardRow>
        <br />
        <h2>
          Contact us today to learn more about how ABC can help with your new
          hire orientation process.
        </h2>
        <br />
        <a target="_blank" href="mailto:rhughes@abcbaltimore.org">
          <CustomButton newSiteButton>Contact ABC Safety Services</CustomButton>
        </a>
      </InnerDiv>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Increase productivity. Improve your safety record.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default NewHirePage;
