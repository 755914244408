import React from "react";
import { Link } from "react-router-dom";

import { TitleBlock, FooterBlock } from "../../App.styles";
import {
  Wrapper,
  VideoBlock,
  IframeContainer,
} from "./managementeducationpage.styles";

const ManagementEducationPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <IframeContainer>
          <VideoBlock
            src="https://player.vimeo.com/video/453885506"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="Management.mp4"
          />
        </IframeContainer>
        <div>
          <h1 className="newSiteHeading">Management Education</h1>
          <p>
            These comprehensive training programs are designed to cover the full
            complement of construction professionals from craft trainee to
            licensed journeyperson to jobsite manager, operations, and more.
          </p>
          <Link to="/academies">
            <h3 className="orange">Academies</h3>
          </Link>
          <Link to="/blueprint-reading">
            <h3 className="orange">Blueprint Reading</h3>
          </Link>
          <Link to="/legal-regulatory">
            <h3 className="orange">Legal & Regulatory</h3>
          </Link>
          <Link to="/operations">
            <h3 className="orange">Operations</h3>
          </Link>
          <Link to="/cqm">
            <h3 className="orange">Construction Quality Management (CQM)</h3>
          </Link>
          <Link to="/technology-education">
            <h3 className="orange">Technology Education</h3>
          </Link>
        </div>
      </TitleBlock>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default ManagementEducationPage;
