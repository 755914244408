import React from "react";
import { Link } from "react-router-dom";

import { scrollToSection } from "../../utils/utility-functions";

import {
  TitleBlock,
  FooterBlock,
  HashLink,
  AccentLineMain,
} from "../../App.styles";
import { Wrapper, ImageCard, Image } from "./peergroupspage.styles";
import CustomButton from "../../components/custom-button/custom-button.component";

const PeerGroupsPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <h1 className="newSiteHeading">Peer Groups</h1>
        </div>
        <div>
          <HashLink onClick={() => scrollToSection("ambassador")}>
            Ambassador Committee
          </HashLink>
          <HashLink onClick={() => scrollToSection("architect-roundtable")}>
            Contractor & Architect Roundtable
          </HashLink>
          <HashLink onClick={() => scrollToSection("education")}>
            Education & Training
          </HashLink>
          <HashLink onClick={() => scrollToSection("eic")}>
            EIC Taskforce
          </HashLink>
          <HashLink onClick={() => scrollToSection("gc-roundtable")}>
            GC Executive Roundtable
          </HashLink>
          <HashLink onClick={() => scrollToSection("hrfc")}>
            Human Resources Forum for Construction (HRFC)
          </HashLink>
          <HashLink onClick={() => scrollToSection("legislative")}>
            Legislative Council
          </HashLink>
          <HashLink onClick={() => scrollToSection("logic")}>LOGIC</HashLink>
          <HashLink onClick={() => scrollToSection("safety")}>
            Safety Professional Peer Group
          </HashLink>
          <HashLink onClick={() => scrollToSection("small-business")}>
            Small Business Growth Committee
          </HashLink>
          <HashLink onClick={() => scrollToSection("young-leaders")}>
            Young Leaders
          </HashLink>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <ImageCard id="ambassador">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2023/04/Ambassador-Logo-2048x2048.jpg"
            alt="man showing off his rockfish catch"
          />
        </Image>
        <div>
          <h3>Ambassador Committee</h3>
          <p>
            Focused on helping members realize the full value and potential of
            their ABC membership
          </p>
          <p>
            <strong>Chair:</strong> Jeanne Schenk, Perry Moving & Storage
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="aschaffer@abcbaltimore.org">
              Ambre Schaffer
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="architect-roundtable" className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/CAR.jpg"
            alt="Contractor & Architect Rountable logo"
          />
        </Image>
        <div>
          <h3 className="white-text">Contractor & Architect Rountable</h3>
          <p>
            Connects contractors, architects and developers so that they may
            discuss common issues and strive to grow the industry as a whole.
          </p>
          <p>
            <strong>Co-Chairs:</strong> Tyler Tate, Lewis Contractors and Sharon
            Day, GWWO, Inc. Architects
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="bchaapel@abcbaltimore.org">
              Brittany Chaapel
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="education">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/education.jpg"
            alt="Education & Training logo"
          />
        </Image>
        <div>
          <h3>Education & Training</h3>
          <p>
            Oversees all aspects of the apprenticeship program, including
            School-to-Career, Craft Training, and Pre-Apprenticeship Programs.
          </p>
          <p>
            <strong>Chair:</strong> Justin Townsley, DEL Electric
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="chadfield@abcbaltimore.org">
              Chris Hadfield
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="eic" className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2025/01/EIC_square.png"
            alt="Excellence in Construction logo"
          />
        </Image>
        <div>
          <h3 className="white-text">EIC Taskforce</h3>
          <p>
            This group works to uphold the integrity of ABC's Excellence in
            Construction program. Overseeing the judging and selection process
            and working to ensure an unbiased and thorough review of each
            submission.
          </p>
          <p>
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="bchaapel@abcbaltimore.org">
              Brittany Chaapel
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="gc-roundtable">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/gc-2048x2048.jpg"
            alt="GC Executive Rountable Logo"
          />
        </Image>
        <div>
          <h3>GC Executive Rountable</h3>
          <p>
            Helps with the issues and concerns of General Contractors in the
            Baltimore Metro area.
          </p>
          <p>
            <strong>Chair:</strong> William Moore, Southway Builders
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="mhenderson@abcbaltimore.org">
              Mike Henderson
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="hrfc" className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/hr-2048x2048.jpg"
            alt="HRFC Logo"
          />
        </Image>
        <div>
          <h3 className="white-text">
            Human Resources Forum for Construction (HRFC)
          </h3>
          <p>
            Led by HR and legal professionals to discuss industry trends, new
            laws and regulations relative to the local construction industry.
          </p>
          <p>
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="mpusateri@abcbaltimore.org">
              Mandi Pusateri
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="legislative">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/legislative.jpg"
            alt="Legislative Council logo"
          />
        </Image>
        <div>
          <h3>Legislative Council</h3>
          <p>
            Comprised of representatives from different Maryland chapters who
            meet in Annapolis to monitor, track, and influence legislation that
            affects the construction industry.
          </p>
          <p>
            <strong>Chair:</strong> Trisha Baldwin, Reliable Contracting
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="mhenderson@abcbaltimore.org">
              Mike Henderson
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="logic" className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/logic.jpg"
            alt="LOGIC logo"
          />
        </Image>
        <div>
          <h3 className="white-text">
            LOGIC, Ladies Operating for Growth in Construction
          </h3>
          <p>
            Represents the interests of women within the construction industry
            and assists with increasing the quality and availability to women
            that will promote a positive image to other women within and outside
            of the industry.
          </p>
          <p>
            <strong>Chair:</strong> Mara L. Sierocinski, Sandy Spring Bank
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="mpusateri@abcbaltimore.org">
              Mandi Pusateri
            </a>
          </p>
          <Link to="/logic-scholarship">
            <CustomButton newSiteButton>
              2025 Martha Elliott LOGIC Scholarship
            </CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://secure.abcbaltimore.org/logic-donation/"
          >
            <CustomButton newSiteButton>Donations</CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://www.linkedin.com/company/abc-greater-baltimore-logic/?viewAsMember=true"
          >
            <CustomButton newSiteButton>LOGIC on LinkedIn</CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=LOGIC&tribe_eventcategory%5B0%5D=53"
          >
            <CustomButton newSiteButton>Events</CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2024/01/LOGIC_Event_Calendar_1.pdf"
          >
            <CustomButton newSiteButton>Events Calendar</CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2024/01/LOGIC_Leadership_Chart.pdf"
          >
            <CustomButton newSiteButton>Leadership Chart</CustomButton>
          </Link>
          {"  "}
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/event/ladies-breakfast-scramble/"
          >
            <CustomButton newSiteButton>
              LOGIC Morning Breakfast Scramble
            </CustomButton>
          </Link>
          {"  "}
          <Link target="_blank" to="https://youtu.be/zNi-uWuYuKw">
            <CustomButton newSiteButton>
              Women in Construction video
            </CustomButton>
          </Link>
        </div>
      </ImageCard>
      <ImageCard id="safety">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/spg.jpg"
            alt="Safety Professional Peer Group logo"
          />
        </Image>
        <div>
          <h3>Safety Professional Peer Group</h3>
          <p>
            Share best practices with fellow safety professional peers, build
            valuable relationships, special training opportunities, plans
            education programs
          </p>
          <p>
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="gcobb@abcbaltimore.org">
              Gene Cobb
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="small-business" className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2025/01/small_business_peer.png"
            alt="Small Business Growth Committee logo"
          />
        </Image>
        <div>
          <h3 className="white-text">Small Business Growth Committee</h3>
          <p>
            This committee advises and plans an annual event that combines BD,
            education & networking including growth topics and upcoming bidding
            opportunities with GCs.
          </p>
          <p>
            <strong>Chair:</strong> Apryl Webb, Smoot Construction
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="mpusateri@abcbaltimore.org">
              Mandi Pusateri
            </a>
          </p>
        </div>
      </ImageCard>
      <ImageCard id="young-leaders">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/yl-2048x2048.jpg"
            alt="Young Leaders of ABC logo"
          />
        </Image>
        <div>
          <h3>Young Leaders of ABC</h3>
          <p>
            Focused specifically on the needs and concerns of young
            professionals in the construction industry.
          </p>
          <p>
            <strong>Chair:</strong>Andrew Holmes, Southway Builders, Inc.
            <br />
            <strong>Vice-Chair:</strong>Greg Komondor, DenverSiteWorks and Bruce
            Damon, Aerotek
            <br />
            <strong>Staff Liaison:</strong>{" "}
            <a target="_blank" href="bchaapel@abcbaltimore.org">
              Brittany Chaapel
            </a>
          </p>
          {/* <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2024/05/YL_Calendar.pdf"
          >
            <CustomButton newSiteButton>Young Leaders Calendar</CustomButton>
          </Link> */}
        </div>
      </ImageCard>
      <FooterBlock>
        <h3 className="white-text">
          For more information on our networking and event sponsorship
          opportunities, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default PeerGroupsPage;
