import styled from "@emotion/styled";

export const BlogPageWrapper = styled.div`
  width: var(--screen-width);
  padding: 75px 20px 0px 150px;
  text-align: center;

  h4 {
    padding: 20px 0px;
    width: 80%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    width: 100vw;
    padding: 20px;
  }
`;

export const BlogInfo = styled.div`
  height: 35%;
  border: 0.5px solid gray;
  border-top: none;
  padding: 10px;
`;

export const BlogTile = styled.div`
  width: 400px;
  height: 320px;
  text-align: left;

  h4 {
    text-align: left;
    margin: 0;
    font-size: 1.2em;
    padding: 0px !important;
  }

  &:hover {
    border-left: 5px solid red;
  }

  box-shadow: var(--shadow-elevation-medium-local);
`;

export const BlogTileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;

export const FeaturedContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 65%;
  // max-height: 260px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center top;
  }
  border-bottom: 2px solid var(--colors-site-mainTheme);
`;

export const ImageList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    display: block;
    margin: 0px auto;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
