import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ImageCard = styled.div`
  padding: 0px 10%;
  margin-top: 20px;
  display: flex;
  jutsify-content: space-evenly;
  align-items: center;
  gap: 30px;

  &.blue {
    background-color: var(--colors-site-mainTheme);
    p {
      color: var(--colors-site-white);
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding: 0 5%;
  }
`;

export const Image = styled.div`
  flex: 1 0 350px;
  align-self: start;

  img {
    object-fit: cover;
    aspect-ratio: 70 / 47;
  }

  @media screen and (max-width: 850px) {
    max-width: 350px;
    flex: 0 0 200px;
  }
`;
