import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./blueprintreadingpage.styles";

const BlueprintReadingPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const blueprintIntroEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Introduction to Blueprint")
  );
  const blueprintMEPEvents = eventsCalendar?.filter((item) =>
    item.title.includes("MEP")
  );
  const blueprintStructuralEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Structural")
  );
  const blueprintArchitecturalEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Architectural")
  );
  const blueprintSiteCivilEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Site/Civil")
  );

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Blueprint Reading</h1>
        </div>
        <div>
          <p>
            Blueprint reading is a fundamental skill that should be mastered by
            all individuals pursuing a career in the construction industry.
          </p>
          <HashLink onClick={() => scrollToSection("intro")}>
            Introduction to Blueprint Reading
          </HashLink>
          <HashLink onClick={() => scrollToSection("mep")}>
            Advanced Blueprint Reading: MEP
          </HashLink>
          <HashLink onClick={() => scrollToSection("structural")}>
            Advanced Blueprint Reading: Structural
          </HashLink>
          <HashLink onClick={() => scrollToSection("architectural")}>
            Advanced Blueprint Reading: Architectural
          </HashLink>
          <HashLink onClick={() => scrollToSection("site-civil")}>
            Advanced Blueprint Reading: Site/Civil
          </HashLink>
          <br />
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="intro">
        <h3>Introduction to Blueprint Reading</h3>
        <p>
          This class has been designed for individuals with little to no
          experience with reading blueprints and will help provide the
          foundation necessary to help individuals master this important skill.
          Potential , students should be proficient with basic mathematics and
          have a basic understanding of commercial building components. Topics
          to include, but not limited to the following:
        </p>
        <ul>
          <li>Types of Construction Drawings and their purpose</li>
          <li>Drawing Organization & Components</li>
          <li>Understanding floor plans, sections & elevations</li>
          <li>
            Identify and understanding the meaning of common symbols, notations
            and abbreviations found on plans
          </li>
        </ul>
        {blueprintIntroEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={blueprintIntroEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${blueprintIntroEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Introduction to Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {blueprintIntroEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/?tribe-bar-search=introduction+blueprint"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="mep">
        <h3>Advanced MEP Blueprint Reading</h3>
        <p>
          This series of classes focuses on construction drawing elements
          important to individuals specializing in the HVAC, Plumbing and
          Electrical trades. Commercial projects often consist of highly complex
          systems and components for the MEP trades. Topics to include, but not
          limited to the following:
        </p>
        <ul>
          <li>Common Mechanical & Electrical Symbols and their meaning</li>
          <li>Abbreviations and terminology used on MEP Plans</li>
          <li>
            Understanding design intent through interpreting MEP plans, sections
            and details
          </li>
          <li>
            Understanding and gathering pertinent information from
            specifications
          </li>
        </ul>
        {blueprintMEPEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={blueprintMEPEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${blueprintMEPEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Advanced MEP Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {blueprintMEPEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Advanced+MEP+Blueprint"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="structural">
        <h3>Advanced Structural Blueprint Reading</h3>
        <p>
          The advanced Structural class will be a continuation of the
          Introductory class and will take a focused and deep dive into the
          structural drawings and related technical specifications. Focus of
          this class will be to review Concrete and Structural Steel System as
          the primary building structure.
        </p>
        {blueprintStructuralEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={blueprintStructuralEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${blueprintStructuralEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Advanced Structural Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {blueprintStructuralEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Advanced+Structural%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="architectural">
        <h3>Advanced Architectural Blueprint Reading</h3>
        <p>
          The advanced Architectural class will be a continuation of the
          Introductory class and will take a focused and deep dive into the
          architectural drawings and related technical specifications. The
          differences between Master Format and Uniformat will be discussed.
          Basic symbol identification will also be reviewed.
        </p>
        {blueprintArchitecturalEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={blueprintArchitecturalEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${blueprintArchitecturalEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Advanced Architectural Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {blueprintArchitecturalEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Advanced+Architectural%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="site-civil">
        <h3>Advanced Site / Civil Blueprint Reading</h3>
        <p>
          This course will expand on a basic understanding of how site
          construction works by learning how to read civil plans. Learn how
          these plans influence how a job will be constructed and how to
          identify issues before construction. This course will cover the
          following:
        </p>
        <ul>
          <li>
            Learn the basic elements that make up a civil plan set, including
            utilities.
          </li>
          <li>
            Talk about the standard layout and notations of different civil
            disciplines.
          </li>
          <li>
            Practice interacting with a civil plan set to find and interpret
            various plans, notes, and details.
          </li>
          <li>
            Learn how to combine basic information from other plan types with
            civil plans for a broader understanding of how the site will be
            constructed.
          </li>
        </ul>
        {blueprintSiteCivilEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={blueprintSiteCivilEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${blueprintSiteCivilEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Advanced Site/Civil Blueprint Reading"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {blueprintSiteCivilEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Advanced+Civil%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default BlueprintReadingPage;
