import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  max-height: 400px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const StaffWrap = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    padding: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 80%;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  max-width: 300px;
  max-height: 350px;
  width: calc((100vw - 150px) / 8);
  height: calc(var(--screen-width) / 6);
  background-image: url(${(props) => props.imageBackground});
  background-position: center;
  background-size: cover;
  box-shadow: var(--shadow-elevation-medium-local);
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 1100px) {
    width: calc(100vw / 6);
    height: calc(100vw / 4);
  }

  @media screen and (max-width: 800px) {
    width: calc(100vw / 3);
    height: calc(100vw / 2);
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: var(--colors-site-white);

    transition: all 0.6s ease;
    opacity: 0;
    z-index: 99;
  }

  &:hover {
    p {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: var(--colors-site-mainThemeOverlay); */
    background: var(--colors-site-mainThemeOverlay) !important;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    /* border: 1px solid hsla( 0, 0%, 100%, 0.18 );  */
    transform: translate(-100%);
    transition: all 0.4s ease;
  }

  &:hover::after {
    transform: translate(0%);
  }

  @media screen and (max-width: 800px) {
    background: var(--colors-site-mainThemeOverlay) !important;
    justify-content: flex-end;
    p {
      opacity: 1;
      font-size: 0.8em;
    }
  }
`;

export const Name = styled.p``;

export const Title = styled.p``;

export const Description = styled.p``;
