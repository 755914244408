import { Link } from "react-router-dom";
import React from "react";
import dayjs from "dayjs";

import { myunescape } from "../../utils/utility-functions";
import LogoContainer from "../../components/logo-container/logo-container.component";
import Spinner from "../../components/spinner/spinner.component";

import newslineHeader from "../../static/images/Newsline_Header.png";

import {
  BlogInfo,
  BlogPageWrapper,
  BlogTile,
  BlogTileWrapper,
  FeaturedContainer,
  ImageList,
  LogoWrapper,
} from "./blogpage.styles";

const BlogPage = ({ blogPosts }) => {
  const mappedBlogList = () => {
    // console.log("MAPPED LIST", blogPosts);
    const list = blogPosts.map((items) => {
      return (
        <div key={items.id}>
          <Link to={`${items.id}`}>
            <BlogTile>
              <FeaturedContainer>
                <img src={items.fimg_url} />
              </FeaturedContainer>
              <BlogInfo>
                <h4>{myunescape(items.title.rendered)}</h4>
                <p>
                  {dayjs(`${items.date}`).format("M/DD/YYYY").toUpperCase()}
                </p>
              </BlogInfo>
            </BlogTile>
          </Link>
        </div>
      );
    });
    // console.log("list", list);
    return list.filter((el) => el !== undefined);
  };

  return (
    <>
      <BlogPageWrapper>
        <LogoWrapper>
          <LogoContainer
            disabled={true}
            className="centered"
            source={newslineHeader}
            alt="ABC Newsline Header"
            widthValue="800px"
            heightValue="auto"
            margin="0"
          />
        </LogoWrapper>
        <h2>What's Happening at ABC Greater Baltimore</h2>
        <br />
        <ImageList>
          {blogPosts && blogPosts.length > 0 ? (
            <BlogTileWrapper>{mappedBlogList()}</BlogTileWrapper>
          ) : (
            <Spinner />
          )}
        </ImageList>
      </BlogPageWrapper>
    </>
  );
};

export default BlogPage;
