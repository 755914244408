import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin: 15px 0px;
  background-color: red;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    margin: 0;
  }

  li {
    color: var(--colors-site-white);
  }
`;
