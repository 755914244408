import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const LogoContainer = ({
  container,
  screenType,
  source,
  altText,
  widthValue = "100%",
  heightValue = "120px",
  link = null,
  margin = "5px",
  disabled,
}) => {
  const styles = {
    width: widthValue,
    height: heightValue,
  };

  return (
    <AbsoluteDiv className={container === "absolute" ? "absolute" : ""}>
      {disabled ? (
        <LogoWrapper className={screenType} style={styles} margin={margin}>
          <img src={source} alt={altText} />
        </LogoWrapper>
      ) : (
        <Link to={link}>
          <LogoWrapper className={screenType} style={styles} margin={margin}>
            <img src={source} alt={altText} />
          </LogoWrapper>
        </Link>
      )}
    </AbsoluteDiv>
  );
};

export default LogoContainer;

const AbsoluteDiv = styled.div`
  &.absolute {
    position: absolute;
    top: 150px;
    z-index: 99;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => props.margin};

  @media screen and (min-width: 1100px) {
    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) {
    max-width: 425px;
    &.desktop {
      display: none;
    }
  }
`;
