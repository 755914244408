import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }

  h3 {
    line-height: 1.5;
  }
`;

export const IframeContainer = styled.div`
  position: relative;
  padding: 0 0 0 10px;
`;

export const VideoBlock = styled.iframe`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 350px;
  @media screen and (max-width: 800px) {
    width: 90vw;
  }
`;
