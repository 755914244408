import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const GlassWrap = styled.div`
  width: 100%;

  background: linear-gradient(
    -90deg,
    hsla(0, 0%, 100%, 0.1) 0%,
    hsla(0, 0%, 100%, 0.88) 100%
  ) !important;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border: 1px solid hsla(0, 0%, 100%, 0.18);
`;

export const FormWrapper = styled.div`
  margin-left: 220px;
  padding: 20px;
  padding-top: 40px;
  width: 50%;
  position: relative;

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 0.8em;
  margin-top: -20px;
  color: red !important;
`;
export const SuccessMessage = styled.p`
  margin-left: 220px;
  font-size: 1.2em;
  color: #07c507;
`;

export const InputLabel = styled.label`
  color: var(--colors-site-mainTheme);
  font-size: 20px;
  font-weight: normal;

  p {
    font-size: 0.8em;
  }
`;
export const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  outline: none;
  margin-bottom: 25px;
`;

export const FormTitle = styled.h3`
  color: var(--colors-site-mainTheme);
`;

export const SectionTitle = styled.h4`
  color: var(--colors-site-mainTheme);
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  max-height: 400px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const Tryptych = styled.div`
  margin-top: 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-shrink: 1;
  width: 100%;
  // height: 300px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const TryptychImage = styled.div`
  width: 30%;
  height: 250px;
  flex: 1 1 400px;
  display: flex;
  justify-content: center;
  align-items: end;
  background-image: url(${(props) => props.tryptychImage});
  background-size: cover; /* Ensures the image covers the area */
  background-position: center; /* Centers the image */

  @media screen and (max-width: 800px) {
    min-height: 150px;
    width: 80%;
  }

  div {
    width: 100%;
    text-align: center;
    background-color: var(--colors-site-mainThemeOverlay);
  }
`;
