import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./legalregulatorypage.styles";

const LegalRegulatoryPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const pevailingWageEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Prevailing Wage")
  );
  const legalWebinarEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Legal Webinar")
  );

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Legal & Regulatory</h1>
        </div>
        <div>
          <HashLink onClick={() => scrollToSection("prevailing-wage")}>
            Prevaling Wage & The Mechanics of Certified Payroll
          </HashLink>
          <HashLink onClick={() => scrollToSection("legal-webinar")}>
            Legal Seminars
          </HashLink>
          <br />
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="prevailing-wage">
        <h3>Prevailing Wage & The Mechanics of Certified Payroll</h3>
        <p>
          Today, more than ever, it is critical you understand the ‘ins and
          outs’ of local & federal prevailing wage laws. Agencies have ramped up
          its numbers of inspectors and they are under orders to scrutinize your
          jobs and certified payroll reports. Topics include:
        </p>
        <ul>
          <li>Overview of Prevailing Wage Laws and How they Work</li>
          <li>Types of Certified Payrolls</li>
          <li>Filing Procedures and Tracking</li>
          <li>Error Resolution</li>
        </ul>
        {pevailingWageEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={pevailingWageEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${pevailingWageEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Prevailing Wage Course"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {pevailingWageEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/?tribe-bar-search=prevailing+wage"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="legal-seminar">
        <h3>Legal Webinars</h3>
        <p>
          Kate Lawrence of Lawrence Law provides ABC members with up-to-date
          legal advice on relevant industry topics. Past topics have included:
        </p>
        <ul>
          <li>How to Review a Subcontract like a Lawyer</li>
          <li>Getting Paid in the Construction Industry</li>
        </ul>
        {legalWebinarEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={legalWebinarEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${legalWebinarEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Legal Webinars"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {legalWebinarEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/?tribe-bar-search=legal+webinar"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default LegalRegulatoryPage;
