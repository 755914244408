import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  InnerDiv,
  FooterBlock,
  TwoColumnGrid,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Wrapper, ImageBlock, Checklist } from "./safetypage.styles";

const SafetyPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2021/05/safetyguy.jpg" />
        </div>
        <div>
          <h1 className="newSiteHeading">Safety</h1>
          <p>
            ABC Baltimore is the largest provider of construction safety
            training in the region, employing a full-time OSHA outreach safety
            trainer and perform jobsite inspections for many of our members.
          </p>
          <br />
          <h2>
            Need safety help now? Contact{" "}
            <a target="_blank" href="mailto:rhughes@abcbaltimore.org">
              Rachel Hughes
            </a>
          </h2>
        </div>
      </TitleBlock>
      <InnerDiv className="blue">
        <h2 className="white-text">Training Courses Offered</h2>
        <TwoColumnGrid>
          <div>
            <Checklist>
              <li>Aerial Lift Safety</li>
              <li>Arcflash Protection</li>
              <li>Asbestos Abatement</li>
              <li>Confined Space</li>
              <li>Crane, Signalman, & Rigger Training</li>
              <li>Crane Signals</li>
              <li>Electrical Safety</li>
              <li>Fall Protection, Forklift, & Aerial Lift Training</li>
              <li>First Aid/CPR</li>
              <li>Flagger Certification</li>
              <li>GHS Hazcom (Global Harmonization)</li>
            </Checklist>
          </div>
          <div>
            <Checklist>
              <li>Ladder Safety</li>
              <li>Lead Abatement</li>
              <li>Mobile Equipment Safety</li>
              <li>New Hire Safety Orientation</li>
              <li>OSHA Record Keeping</li>
              <li>OSHA 10 Hour & OSHA 30 Hour</li>
              <li>Respirator Protection</li>
              <li>Rigging</li>
              <li>Safety Management</li>
              <li>Scaffold User OSHA Compliance Training</li>
              <li>Silica Awareness</li>
            </Checklist>
          </div>
        </TwoColumnGrid>
      </InnerDiv>
      <InnerDiv>
        <h2>
          To Learn More About Our Spanish Language Classes Contact{" "}
          <a target="_blank" href="mailto:rhughes@abcbaltimore.org">
            rhughes@abcbaltimore.org
          </a>
        </h2>
        <br />
        <Link
          target="_blank"
          to="https://events.abcbaltimore.org/events/category/safety/"
        >
          <CustomButton newSiteButton>Safety Training Calendar</CustomButton>
        </Link>
      </InnerDiv>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Increase productivity. Improve your safety record.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default SafetyPage;
