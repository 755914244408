import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  InnerDiv,
  FooterBlock,
  TwoColumnGrid,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Wrapper, ImageBlock, Checklist } from "./safetypeergrouppage.styles";

const SafetyPeerGroupPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2021/05/SAFETY3.jpeg" />
        </div>
        <div>
          <h1 className="newSiteHeading">Safety Professional Peer Group</h1>
          <p>
            On the third Tuesday of every month, a somewhat unusual event takes
            place. That's when ABC members, safety professionals, and
            representatives of Maryland's Occupational Safety and Health (MOSH)
            come together to share their insider knowledge with one another. No
            question too small. No safety topic off limits. No judgement. ABC
            Baltimore's headquarters turns into a safe space for one of the
            association's newest, and most vocal, peer groups: The ABC Safety
            Professional Peer Group.
          </p>
          <p>
            The monthly one-and-a-half hour meetings each have a specific
            agenda, with one of the topics focused on one of the 20 key
            components of safety outlined in STEP. Members also get an update on
            the SPECS program. Safety statistics and related best practices are
            also discussed. Safety professionals, medical providers from local
            occupational health facilities, and insurance loss control
            professionals provide expert insights to the group members.
          </p>
        </div>
      </TitleBlock>
      <InnerDiv>
        <h2>
          Contact us today to learn about how the ABC Safety Peer Group can
          benefit your company.
        </h2>
        <br />
        <a target="_blank" href="mailto:jcobb@abcbaltimore.org">
          <CustomButton newSiteButton>
            Contact ABC Safety Peer Group
          </CustomButton>
        </a>
      </InnerDiv>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Increase productivity. Improve your safety record.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default SafetyPeerGroupPage;
