import React, { useState } from "react";
import { useForm, Controller, set } from "react-hook-form";

import Page from "../../components/page/page.component";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import Spinner from "../../components/spinner/spinner.component";
import { AccentLineThin, TitleBlock } from "../../App.styles";

import {
  Wrapper,
  FormWrapper,
  FormContainer,
  ErrorMessage,
  InputLabel,
  SuccessMessage,
} from "./grassrootspage.styles";

const GrassRootsPage = ({}) => {
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      input_1: "",
      input_3: "",
      input_4: "",
      input_5: "",
      input_6: "",
      input_7_1: "",
      input_10_1: "",
      input_10_2: "",
      input_10_3: "",
      input_10_4: "",
      input_12_1: "",
      input_12_2: "",
      input_12_3: "",
      input_12_4: "",
      input_12_5: "",
      input_12_6: "",
      input_12_7: "",
      input_12_8: "",
      input_12_9: "",
      input_12_11: "",
      input_12_12: "",
      input_12_13: "",
      input_14: "",
      input_15: "",
      input_16: "",
    },
  });

  const onSubmit = (value) => {
    setSuccess();
    setProcessing(true);
    const formElement = document.forms["grassrootsform"],
      { action, method } = formElement,
      formData = new FormData(formElement);

    for (var value of formData.entries()) {
      // console.log(value);
    }

    const options = {
      method,
      body: formData,
    };

    fetch(action, options)
      .then((response) => response.json())
      .then((response) => {
        setProcessing(false);
        // console.log(response)

        if (!response.is_valid) {
          setSuccess(false);
        } else {
          setSuccess(true);
        }
      })
      .catch((error) => {});
  };

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">ABC Grassroots Action Network</h1>
        </div>
      </TitleBlock>
      <AccentLineThin />
      <FormContainer>
        {success ? (
          <SuccessMessage>
            Thank you for your Submission. Someone will contact you soon.
          </SuccessMessage>
        ) : (
          <FormWrapper>
            <h3>General Information</h3>

            <form
              id="grassrootsform"
              onSubmit={handleSubmit(onSubmit)}
              action="https://dev.abcbaltimore.org/wp-json/gf/v2/forms/19/submissions"
              method="post"
            >
              <Controller
                control={control}
                name="input_1"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*Name" />}
              />
              {errors.input_1?.type === "required" && (
                <ErrorMessage>"Name is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_3"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput {...field} label="*Company" />
                )}
              />
              {errors.input_3?.type === "required" && (
                <ErrorMessage>"Company is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_4"
                rules={{
                  required: true,
                  pattern:
                    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Phone Number" />
                )}
              />
              {errors.input_4?.type === "required" && (
                <ErrorMessage>"Phone Number is Required"</ErrorMessage>
              )}
              {errors.input_4?.type === "pattern" && (
                <ErrorMessage>"Phone Number Not Valid"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5"
                rules={{
                  required: true,
                  pattern:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Email Address" />
                )}
              />
              {errors.input_5?.type === "required" && (
                <ErrorMessage>"Email is required"</ErrorMessage>
              )}
              {errors.input_5?.type === "pattern" && (
                <ErrorMessage>
                  "Please Enter a Valid Email Address"
                </ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_6"
                rules={{
                  required: true,
                  pattern: /^\d{5}(-\d{4})?$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Zipcode" />
                )}
              />
              {errors.input_6?.type === "required" && (
                <ErrorMessage>"Zipcode is Required"</ErrorMessage>
              )}
              {errors.input_6?.type === "pattern" && (
                <ErrorMessage>"Please Enter a Valid Zipcode"</ErrorMessage>
              )}
              <br />
              <h4>
                Yes! I would like to be a part of ABC’s grassroots network to
                ensure that local and state officials understand the unique
                challenges facing the commercial construction industry.
              </h4>

              <br />
              <Controller
                control={control}
                name="input_7"
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      name="input_7_1"
                      id="consent"
                      type="checkbox"
                      value="1"
                    />
                    <label htmlFor="consent">&nbsp; I consent</label>
                  </>
                )}
              />
              <br />
              <br />
              <br />
              <h4>
                I am interested in participating in the following (please check
                as many that apply):
              </h4>

              <br />
              <Controller
                control={control}
                name="input_10"
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <label htmlFor="council">
                      <input
                        {...field}
                        name="input_10_1"
                        id="council"
                        type="checkbox"
                        value="Join ABC’s Joint Legislative Council"
                      />{" "}
                      Join ABC’s Joint Legislative Council
                    </label>
                    <br />
                    <label htmlFor="staff">
                      <input
                        {...field}
                        name="input_10_2"
                        id="staff"
                        type="checkbox"
                        value="Accompany ABC Staff to meet [in person/or remote]
                      with elected officials and or regulatory staff"
                      />{" "}
                      Accompany ABC Staff to meet [in person/or remote] with
                      elected officials and or regulatory staff
                    </label>
                    <br />
                    <label htmlFor="letters">
                      <input
                        {...field}
                        name="input_10_3"
                        id="letters"
                        type="checkbox"
                        value="Send letters of support/opposition/or concern
                      regarding proposed legislation or regulatory changes"
                      />{" "}
                      Send letters of support/opposition/or concern regarding
                      proposed legislation or regulatory changes
                    </label>
                    <br />
                    <label htmlFor="testify">
                      <input
                        {...field}
                        name="input_10_4"
                        id="testify"
                        type="checkbox"
                        value="Testify in support or opposition to legislation"
                      />{" "}
                      Testify in support or opposition to legislation
                    </label>
                    <br />
                  </>
                )}
              />
              <br />
              <br />
              <h4>
                Topics with which you feel comfortable enough to testify
                <br /> (Please check all topics with which you are familiar):
              </h4>

              <br />
              <Controller
                control={control}
                name="input_12"
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <label htmlFor="apprenticeship">
                      <input
                        {...field}
                        name="input_12_1"
                        id="apprenticeship"
                        type="checkbox"
                        value="Apprenticeship"
                      />{" "}
                      Apprenticeship
                    </label>
                    <br />

                    <label htmlFor="bonding">
                      <input
                        {...field}
                        name="input_12_2"
                        id="bonding"
                        type="checkbox"
                        value="Bonding"
                      />{" "}
                      Bonding
                    </label>
                    <br />
                    <label htmlFor="human resources">
                      <input
                        {...field}
                        name="input_12_3"
                        id="human resources"
                        type="checkbox"
                        value="Human Resources"
                      />{" "}
                      Human Resources
                    </label>
                    <br />

                    <label htmlFor="insurance">
                      <input
                        {...field}
                        name="input_12_4"
                        id="insurance"
                        type="checkbox"
                        value="Insurance"
                      />{" "}
                      Insurance
                    </label>
                    <br />
                    <label htmlFor="Mechanic Lien Laws">
                      <input
                        {...field}
                        name="input_12_5"
                        id="Mechanic Lien Laws"
                        type="checkbox"
                        value="Mechanic Lien Laws"
                      />{" "}
                      Mechanic Lien Laws
                    </label>
                    <br />
                    <label htmlFor="MWBE Goal Participation">
                      <input
                        {...field}
                        name="input_12_6"
                        id="MWBE goal participation"
                        type="checkbox"
                        value="MWBE Goal Participation"
                      />{" "}
                      MWBE Goal Participation
                    </label>
                    <br />
                    <label htmlFor="Pre-qualification guidelines">
                      <input
                        {...field}
                        name="input_12_7"
                        id="Pre-qualification guidelines"
                        type="checkbox"
                        value="Pre-qualification Guidelines"
                      />{" "}
                      Pre-qualification Guidelines
                    </label>
                    <br />
                    <label htmlFor="Prevailing Wage">
                      <input
                        {...field}
                        name="input_12_8"
                        id="Prevailing Wage"
                        type="checkbox"
                        value="Prevailing Wage"
                      />{" "}
                      Prevailing Wage
                    </label>
                    <br />
                    <label htmlFor="Procurement laws and regulations">
                      <input
                        {...field}
                        name="input_12_9"
                        id="Procurement laws and regulations"
                        type="checkbox"
                        value="Procurement Laws and Regulations"
                      />{" "}
                      Procurement Laws and Regulations
                    </label>
                    <br />
                    <label htmlFor="Trade Licensing">
                      <input
                        {...field}
                        name="input_12_11"
                        id="Trade Licensing"
                        type="checkbox"
                        value="Trade Licensing"
                      />{" "}
                      Trade Licensing
                    </label>
                    <br />

                    <label htmlFor="Retainage">
                      <input
                        {...field}
                        name="input_12_12"
                        id="Retainage"
                        type="checkbox"
                        value="Retainage"
                      />{" "}
                      Retainage
                    </label>
                    <br />

                    <label htmlFor="Safety">
                      <input
                        {...field}
                        name="input_12_13"
                        id="Safety"
                        type="checkbox"
                        value="Safety"
                      />{" "}
                      Safety
                    </label>
                    <br />
                  </>
                )}
              />
              <br />

              <br />
              <br />

              <h4>Company Principal Authorization</h4>
              <p>
                (this is only necessary if the person volunteering is not a
                principal owner or officer)
              </p>

              <Controller
                control={control}
                name="input_14"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*Name" />}
              />
              {errors.input_14?.type === "required" && (
                <ErrorMessage>"Name is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_15"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*Title" />}
              />
              {errors.input_15?.type === "required" && (
                <ErrorMessage>"Title is Required"</ErrorMessage>
              )}

              <InputLabel htmlFor="input_16">Date</InputLabel>
              <br />
              <input
                {...register("input_16", { required: true })}
                type="date"
              />
              <br />
              <br />
              {errors.input_16?.type === "required" && (
                <ErrorMessage>"Date is Required"</ErrorMessage>
              )}
              <br />

              <CustomButton newSiteButton>Submit Application</CustomButton>
              <br />
              <br />
              {isSubmitted && isSubmitSuccessful === false ? (
                <ErrorMessage>Please check the form for errors</ErrorMessage>
              ) : null}
            </form>
            {processing ? <Spinner /> : null}
            {success === false ? (
              <ErrorMessage>
                There was an error processing your information. Please try again
                or contact ABC for help.
              </ErrorMessage>
            ) : null}
          </FormWrapper>
        )}
      </FormContainer>
    </Wrapper>
  );
};

export default GrassRootsPage;
