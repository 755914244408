import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import { Paragraph, Wrapper } from "./cqmpage.styles";

const CQMPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const cqmEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Construction Quality Management")
  );

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Construction Quality Management</h1>
        </div>
        <div>
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="construction-101">
        <h3>Construction Quality Management (CQM) for Contractors</h3>
        <p>
          Construction Quality Management for Contractors is a certification
          course sponsored by USACE and NAVFAC. This certification is usually a
          contract requirement and is an integral component of quality
          management on Federal government construction projects.
        </p>
        {cqmEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={cqmEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${cqmEvents[0].start_date}`).format("MMM D @ h:mma")}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:cqm@abcbaltimore.org?subject=Interest in Construction Quality Management for Contractors"
            >
              cqm@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {cqmEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=CQM"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default CQMPage;
