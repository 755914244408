import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }

  h3 {
    line-height: 1.5;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  max-height: 400px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const ImageCard = styled.div`
  padding: 0px 10%;
  margin-top: 20px;
  display: flex;
  jutsify-content: space-evenly;
  align-items: center;
  gap: 30px;

  &.blue {
    background-color: var(--colors-site-mainTheme);
    p {
      color: var(--colors-site-white);
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding: 0 5%;
  }
`;

export const Image = styled.div`
  flex: 1 0 350px;
  align-self: start;

  img {
    object-fit: cover;
    aspect-ratio: 70 / 47;
  }

  @media screen and (max-width: 850px) {
    max-width: 350px;
    flex: 0 0 200px;
  }
`;

export const IframeContainer = styled.div`
  position: relative;
  padding: 0 0 0 10px;
`;

export const VideoBlock = styled.iframe`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 350px;
  @media screen and (max-width: 800px) {
    width: 90vw;
  }
`;
