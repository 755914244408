import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Login from "../../components/login/login.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import {
  AccentLineMain,
  InnerDiv,
  TitleBlock,
  FooterBlock,
} from "../../App.styles";

import {
  Wrapper,
  LoggedOutWrapper,
  InnerWrapper,
} from "./otherresourcespage.styles";

const OtherResourcesPage = ({ setToken, token }) => {
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    console.log("Loged in User", loggedInUser);
    console.log("HI");
    if (loggedInUser) {
      setToken(loggedInUser);
    } else {
      // console.log(state.theme.token);
    }
  }, [token]);

  return (
    <>
      <Wrapper>
        <TitleBlock>
          <h1 className="newSiteHeading">Other Member Resources</h1>
        </TitleBlock>
        <AccentLineMain />
        <InnerDiv>
          <Link
            target="_blank"
            to="mailto:mhenderson@abcbaltimore.org?subject=Please send me the Merit Shop Craft Wage Survey for Baltimore and for Maryland"
          >
            <CustomButton newSiteButton>
              Wage Data for Skilled Trades
            </CustomButton>
          </Link>
          <br />
          <br />
          <br />
          <h2>New Hire Safety Orientation Materials</h2>
          <br />
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2022/01/Safety-Orientation-Facilitator-Guide.pdf"
          >
            <CustomButton newSiteButton>Facilitator Guide</CustomButton>
          </Link>
          {"     "}
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2022/01/Safety-Orientation-Participant-Guide.pdf"
          >
            <CustomButton newSiteButton>Participant Guide</CustomButton>
          </Link>
          {"     "}
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2022/01/Safety-Orientation-PowerPoint.pptx"
          >
            <CustomButton newSiteButton>PowerPoint Template</CustomButton>
          </Link>
        </InnerDiv>
        <FooterBlock>
          <h2 className="newSiteHeading">
            Grow your career with us. Be more with ABC Baltimore.
          </h2>
        </FooterBlock>
      </Wrapper>
      {token ? null : (
        <LoggedOutWrapper>
          <InnerWrapper>
            <Login setToken={setToken} />
          </InnerWrapper>
        </LoggedOutWrapper>
      )}
    </>
  );
};

export default OtherResourcesPage;
