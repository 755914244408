import React from "react";

import Page from "../../components/page/page.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import { Link } from "react-router-dom";

import { TitleBlock, FooterBlock, HashLink } from "../../App.styles";
import { scrollToSection } from "../../utils/utility-functions";

import {
  Row,
  Column,
  Overlay,
  Wrapper,
  ImageBlock,
  Name,
  Title,
  Description,
  StaffWrap,
} from "./aboutuspage.styles";

const staffList = require("../../static/StaffMemberData.js");
const boardList = require("../../static/BoardMemberData.js");

console.log("BOARD LIST", boardList.default);

const AboutUsPage = ({}) => {
  const columnNumber = 4;
  const staffListSplit = staffList.default
    .map((e, i) => {
      return i % columnNumber === 0
        ? staffList.default.slice(i, i + columnNumber)
        : null;
    })
    .filter((e) => {
      return e;
    });
  const boardListSplit = boardList.default
    .map((e, i) => {
      return i % columnNumber === 0
        ? boardList.default.slice(i, i + columnNumber)
        : null;
    })
    .filter((e) => {
      return e;
    });

  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/ABC-map-FINAL2.jpeg" />
        </div>
        <div>
          <h1 className="newSiteHeading">About Us</h1>
          <p>
            Associated Builders and Contractors of Greater Baltimore is the
            largest organization in Maryland to represent the commercial
            construction industry. Our 650 members represent the leading general
            contractors and specialty contractors in the region.
            <br />
            <br />
            PHONE - 410-821-0351
            <br />
            2101 E. Biddle St., Suite 5000 Baltimore, MD 21213 <br />
            HOURS - Monday - Friday 8 AM - 5 PM
          </p>
          <Link to="https://www.abc.org/">
            <CustomButton newSiteButton>ABC National</CustomButton>
          </Link>
          <HashLink onClick={() => scrollToSection("staff")}>
            <CustomButton newSiteButton>ABC Staff</CustomButton>
          </HashLink>
        </div>
      </TitleBlock>
      <StaffWrap>
        <h2>ABC Board Members</h2>
        <br />
        <Row key={Math.random()}>
          {boardList.default.map((item, index) => {
            return (
              <a
                key={`${Math.random()} + ${index}`}
                href={`mailto:${item.email}`}
                target="_blank"
              >
                <Column key={item.id} imageBackground={item.Image}>
                  <Overlay>
                    <Name>{item.Name}</Name>
                    <Title>{item.Title}</Title>
                    <Description>{item.Description}</Description>
                    <p>{item.image}</p>
                  </Overlay>
                </Column>
              </a>
            );
          })}
        </Row>
        <br />
        <br />
        <h2 id="staff">ABC Staff</h2>
        <br />
        <Row key={Math.random()}>
          {staffList.default.map((item, index) => {
            return (
              <a
                key={`${Math.random()} + ${index}`}
                href={`mailto:${item.email}`}
                target="_blank"
              >
                <Column key={item.id} imageBackground={item.Image}>
                  <Overlay>
                    <Name>{item.Name}</Name>
                    <Title>{item.Title}</Title>
                  </Overlay>
                </Column>
              </a>
            );
          })}
        </Row>
      </StaffWrap>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default AboutUsPage;
