import React from "react";
import { Link } from "react-router-dom";

import { TitleBlock, FooterBlock } from "../../App.styles";

import { Wrapper, ImageCard, Image } from "./signatureeventspage.styles";

const SignatureEventsPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <h1 className="newSiteHeading">Signature Events</h1>
        <div>{` `}</div>
      </TitleBlock>
      <ImageCard>
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/eic-1024x684.jpg"
            alt="excellence in construction award photo"
          />
        </Image>
        <div>
          <h3>Excellence in Construction - October</h3>
          <p>
            A night of networking and recognition ABC Greater Baltimore
            Excellence in Construction program is a yearly self-nominating award
            in which general and specialty contractors submit their projects for
            recognition. The EIC program is the premier competition within the
            region that recognizes outstanding projects ABC members have built.
          </p>
        </div>
      </ImageCard>
      <ImageCard className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/may2-1-1024x708.jpg"
            alt="Women at an ABC Networking event"
          />
        </Image>
        <div>
          <h3 className="white-text">Networking Events - Held Monthly</h3>
          <p>
            With multiple events throughout the year, these networking
            opportunities are not only an excellent chance to expand your
            network, but also to enjoy a good time with other ABC members.
          </p>
        </div>
      </ImageCard>
      <ImageCard>
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/fish-1024x683.jpg"
            alt="man showing off his rockfish catch"
          />
        </Image>
        <div>
          <h3>Rockfish Tournament - June</h3>
          <p>
            Come out for a morning on the bay competing for cash prizes! ABC
            charters 8+ boats for an all-inclusive event that includes food,
            drink, fishing equipment and contest entry. No fishing experience
            needed to enjoy this signature event!
          </p>
        </div>
      </ImageCard>
      <ImageCard className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/10/golf-1024x678.jpg"
            alt="People standing on a golf course"
          />
        </Image>
        <div>
          <h3 className="white-text">ABC Golf Classic - June</h3>
          <p>
            Enjoy a day on the links with more than 200 ABC Greater Baltimore
            members. This all-inclusive day on the course includes food, drink,
            golf, contests and more!
          </p>
        </div>
      </ImageCard>
      <ImageCard>
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/12/68679964_10157275406713604_7148039991136878592_n-2-1024x683.jpg"
            alt="Group of people eating crabs"
          />
        </Image>
        <div>
          <h3>Bands By the Bay - August</h3>
          <p>
            Spend the afternoon and evening at the area’s most gorgeous
            waterfront venue, while enjoying all-you-can-eat hard crabs, a bull
            roast menu, sides and unlimited beer, wine and soft drinks. There
            are a host of activities throughout the evening including local
            bands, corn hole, horse shoes, raffles, contests and much more!
          </p>
        </div>
      </ImageCard>
      <ImageCard className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/12/15110491_10154639932488604_6482511836676231861_o-1024x683.jpg"
            alt="Anirban Basu meeting with members"
          />
        </Image>
        <div>
          <h3 className="white-text">State of the Industry - November</h3>
          <p>
            Join ABC Greater Baltimore’s most prominent members for an update on
            the organization, a message from our board chairman, presentation of
            our safety STEP awards, member milestone anniversaries and more!
            Includes a special presentation and economic forecast by ABC Chief
            Economist Anirban Basu.
          </p>
        </div>
      </ImageCard>
      <ImageCard>
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2024/10/Meet_Primes_signature.jpg"
            alt="Meet the Primes event at ABC Greater Baltimore"
          />
        </Image>
        <div>
          <h3>Meet The Primes - Spring and Fall</h3>
          <p>
            These unique events strategically place general contractors in the
            same room with qualified subcontractors. Members will ‘speed
            network’ their way through classrooms at the Construction Education
            Academy (CEA) meeting with each of the participating general
            contractors, connecting to potential future opportunities. These
            unique events strategically place general contractors in the same
            room with qualified subcontractors. Members will ‘speed network’
            their way through classrooms at the Construction Education Academy
            (CEA) meeting with each of the participating general contractors,
            connecting to potential future opportunities.
          </p>
        </div>
      </ImageCard>
      <ImageCard className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2024/10/Apprenticeship_Graduation_Signature.jpg"
            alt="Anirban Basu meeting with members"
          />
        </Image>
        <div>
          <h3 className="white-text">Apprenticeship Graduation - May</h3>
          <p>
            Join graduates, employers, and supporters of our ABC Apprenticeship
            program as we celebrate another successful graduating class. Enjoy
            speakers, awards and light refreshments as we honor the hard work of
            our student's completion of our four-year program.
          </p>
        </div>
      </ImageCard>
      <ImageCard>
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2024/10/LOGIC_Events_Signature.jpg"
            alt="Meet the Primes event at ABC Greater Baltimore"
          />
        </Image>
        <div>
          <h3>LOGIC Events & Fundraisers</h3>
          <p>
            Throughout the year our Ladies Operating for Growth in Construction
            (LOGIC) group hosts various networking and outreach events. Most
            events raise proceeds for the{" "}
            <Link to="/logic-scholarship">
              Martha Elliott LOGIC Scholarship Fund
            </Link>
            . These events are wonderful way to support women in construction
            and grow the industry.
          </p>
        </div>
      </ImageCard>
      <FooterBlock>
        <h3 className="white-text">
          For more information on our networking and event sponsorship
          opportunities, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default SignatureEventsPage;
