import React from "react";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./keydatespage.styles";

const KeyDatesPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Apprenticeship FAQ</h1>
        </div>
        <div>
          <HashLink onClick={() => scrollToSection("key-dates")}>
            Key Dates
          </HashLink>
          <HashLink onClick={() => scrollToSection("locations")}>
            Locations
          </HashLink>
          <HashLink onClick={() => scrollToSection("call")}>
            Who to Call?
          </HashLink>
          <HashLink onClick={() => scrollToSection("team")}>
            Apprenticeship Team
          </HashLink>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="key-dates">
        <h3>Key Dates</h3>
        <br />
        <strong>Apprenticehip Orientation (Mandatory):</strong>
        <ul>
          <li>Thursday, January 9th from 5-8pm</li>
        </ul>
        <br />
        <strong>First Regular Day of Classes:</strong>
        <ul>
          <li>
            Monday, September 16th (Those on a Monday /Wednesday schedule)
          </li>
          <li>
            Tuesday, September 17th (Those on a Tuesday/Thursday schedule)
          </li>
        </ul>
        <p>
          - Please check your flashpoint account for the most updated school
          schedule -
        </p>
        <strong>2024/25 Graduation Date:</strong>
        <ul>
          <li>Thursday May 1st, 5-7 pm CCBC Catonsville Gym.</li>
        </ul>
      </Paragraph>
      <Paragraph id="locations">
        <h3>LOCATIONS & TRADES:</h3>
        <br />
        <strong>
          East Baltimore (Construction Education Academy; main campus)
        </strong>
        <ul>
          <li>
            Electrical 1-2; Plumbing 1-4; Carpentry 1-4; Sheet Metal: 1-4;
            Sprinkler Fitter: 1-4; HVACR 1-4
          </li>
        </ul>
        <br />
        <strong>Federal Hill (Electrical Training Facility)</strong>
        <ul>
          <li>Electrical: Years 2-4</li>
        </ul>
        <br />
        <strong>Owings Mills — CCBC Campus; Owings Mills</strong>
        <ul>
          <li>Electrical: Years 1-2; Other trades; TBD</li>
        </ul>
      </Paragraph>
      <Paragraph id="call">
        <h3>WHO DO YOU CALL?</h3>
        <strong>
          Main Line: 410-821-0351 + ext.
          <br />
          (For employers and students)
        </strong>
        <br />
        <br />
        <ul>
          <li>
            Status of Applicant:{" "}
            <a target="_blank" href="mailto:fmalashuk@abcbaltimore.org">
              fmalashuk@abcbaltimore.org
            </a>{" "}
            (Faith) ext: 116
          </li>
          <li>
            Status of MATC Registration:{" "}
            <a target="_blank" href="mailto:sglasgow@abcbaltimore.org">
              sglasgow@abcbaltimore.org
            </a>{" "}
            (Siona) ext: 121
          </li>
          <li>
            Seeking qualified candidates:{" "}
            <a target="_blank" href="mailto:sburley@abcbaltimore.org">
              sburley@abcbaltimore.org
            </a>{" "}
            (Sharon) ext: 122
          </li>
          <li>
            Tuition / Invoicing:{" "}
            <a target="_blank" href="mailto:fmalashuk@abcbaltimore.org">
              fmalashuk@abcbaltimore.org
            </a>{" "}
            (Faith) ext: 116
          </li>
          <li>
            How employers can cover the cost of Apprenticeship Tuition:
            <a target="_blank" href="mailto:chadfield@abcbaltimore.org">
              chadfield@abcbaltimore.org
            </a>{" "}
            (Chris) ext: 101
          </li>
          <li>
            On the Job Training Questions:{" "}
            <a target="_blank" href="mailto:sglasgow@abcbaltimore.org">
              sglasgow@abcbaltimore.org
            </a>{" "}
            (Siona) ext: 121
          </li>
          <li>
            Questions about Instructors:{" "}
            <a target="_blank" href="mailto:dlee@abcbaltimore.org">
              dlee@abcbaltimore.org
            </a>{" "}
            (Donte) ext: 120
          </li>
          <li>
            Questions about curriculum:{" "}
            <a target="_blank" href="mailto:dlee@abcbaltimore.org">
              dlee@abcbaltimore.org
            </a>{" "}
            (Donte) ext: 120
          </li>
          <li>
            Questions about licensing requirements:{" "}
            <a target="_blank" href="mailto:chadfield@abcbaltimore.org">
              chadfield@abcbaltimore.org
            </a>
            (Chris) ext: 101
          </li>
          <li>
            Prep classes for Masters exams:{" "}
            <a target="_blank" href="mailto:chadfield@abcbaltimore.org">
              chadfield@abcbaltimore.org
            </a>{" "}
            (Chris) ext: 101
          </li>
          <li>
            Questions about prevailing wage issues:{" "}
            <a target="_blank" href="mailto:bruce@abcgima.org"></a>
            bruce@abcgima.org (Bruce) 443-956-9139
          </li>
          <li>
            Concerns:{" "}
            <a target="_blank" href="mailto:chadfield@abcbaltimore.org">
              chadfield@abcbaltimore.org
            </a>{" "}
            (Chris) ext: 101
          </li>
        </ul>
        <br />
      </Paragraph>
      <Paragraph id="team">
        <h3>Apprenticeship Team</h3>
        <ul>
          <li>
            <a target="_blank" href="mailto:chadfield@abcbaltimore.org">
              Chris Hadfield
            </a>
            : Vice President of Workforce Development
          </li>
          <li>
            {" "}
            <a target="_blank" href="mailto:dlee@abcbaltimore.org">
              Dante Lee
            </a>
            : Director of Education
          </li>
          <li>
            {" "}
            <a target="_blank" href="mailto:sburley@abcbaltimore.org">
              Sharon Burley
            </a>
            : Job Placement
          </li>
          <li>
            {" "}
            <a target="_blank" href="mailto:fmalashuk@abcbaltimore.org">
              Faith Malashuk
            </a>
            : Compliance and Administration Supervisor
          </li>
          <li>
            {" "}
            <a target="_blank" href="mailto:sglasgow@abcbaltimore.org">
              Siona Glasgow
            </a>
            : Apprenticeship Coordinator
          </li>
          <li>
            <a target="_blank" href="mailto:mbutt@abcbaltimore.org">
              Michelle Butt
            </a>
            : Special Projects
          </li>
        </ul>
        <br />
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information or assistance, contact{" "}
          <a target="_blank" href="mailto:sburley@abcbaltimore.org">
            sburley@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default KeyDatesPage;
