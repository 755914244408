import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  InnerDiv,
  FooterBlock,
  TwoColumnGrid,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";

import {
  Wrapper,
  ImageBlock,
  ClassNotice,
  Arrow,
  Checklist,
} from "./apprenticeshippage.styles";

const ApprenticeshipPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2021/12/Craft-Comp-2021a.jpg" />
        </div>
        <div>
          <h1 className="newSiteHeading">Apprenticeship</h1>
          <h2>Start Your Career Today</h2>
          <p>
            Apprenticeship is a combination of on-the-job training (OJT) and
            related classroom instruction under the supervision of a
            journey-level craft person or trade professional in which workers
            learn the practical and theoretical aspects of a highly skilled
            occupation. ABC provides apprenticeship training in the following
            trades: electric, plumbing, HVACR, carpentry, sheet metal, sprinkler
            fitting.
          </p>
          <ClassNotice>
            Now accepting applications for Fall 2025/26 school year
          </ClassNotice>
        </div>
      </TitleBlock>
      <InnerDiv className="blue">
        <TwoColumnGrid className="eighty">
          <div>
            <Link
              target="_blank"
              to="https://workforce.flashpoint.xyz/Lite/Applications/Edit?templateId=943297001"
            >
              <CustomButton newSiteButton>Online Application</CustomButton>
            </Link>
            <br />
            <Link
              target="_blank"
              to="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/Sample-Math-Test.pdf"
            >
              <CustomButton newSiteButton>Sample Math Test</CustomButton>
            </Link>
            <br />
            <Link to="/apprenticeship-trust">
              <CustomButton newSiteButton>
                Apprenticeship Trust Fund
              </CustomButton>
            </Link>
          </div>
          <div>
            <Link to="/apprentice-resume">
              <CustomButton newSiteButton>Online Resume Tool</CustomButton>
            </Link>
            <Link to="/key-dates">
              <CustomButton newSiteButton>
                2024/25 Apprenticeship FAQ
              </CustomButton>
            </Link>
          </div>
        </TwoColumnGrid>
      </InnerDiv>
      <InnerDiv>
        <h2>Apprenticeship Subsidies</h2>
        <TwoColumnGrid className="eighty">
          <div>
            <Link
              target="_blank"
              to="https://www.dllr.state.md.us/employment/mbw.shtml"
            >
              <CustomButton newSiteButton>Maryland Business Works</CustomButton>
            </Link>
          </div>
          <div>
            <Link
              target="_blank"
              to="https://dev.abcbaltimore.org/wp-content/uploads/2024/08/Lunch-and-Learn-Apprenticeship-Funding.pdf"
            >
              <CustomButton newSiteButton>
                Lunch and Learn Apprenticeship Funding
              </CustomButton>
            </Link>
          </div>
        </TwoColumnGrid>
        <br />
        <p>
          View the recording of our webinar on What Every Participating Employer
          Needs to Know about Apprenticeship.
          <br />
          The password to the video is: AYi?&34T
        </p>
        <Link
          target="_blank"
          to="https://zoom.us/rec/share/0zTpK5gbOf-n1nsoFf44MaeQDmsyOvIEcgnY5hiaVcpwZ_w1Oc6Mqkp3EdGpTycm.vdHE-uC7__ZzqS-5?startTime=1723132579000"
        >
          <CustomButton newSiteButton>View Video</CustomButton>
        </Link>
      </InnerDiv>
      <InnerDiv>
        <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2023/04/appramb.png" />
        <br />
        <p>
          ABC Greater Baltimore has been designated an Apprenticeship Ambassador
          by the state of Maryland for its high commitment to apprenticeships.
          <a
            target="_blank"
            href="https://www.dllr.state.md.us/employment/appr/apprambsum.pdf"
          >
            {" "}
            READ MORE
          </a>
        </p>
        <br />
      </InnerDiv>
      <InnerDiv className="blue">
        <TwoColumnGrid>
          <div>
            <Arrow>&#x21fe;</Arrow>
            <h2 className="white-text">
              Address the industry’s need to stay competitive by investing in
              the development and continuous advancement of its workforce.
            </h2>
          </div>
          <div>
            <Arrow>&#x2691;</Arrow>
            <h2 className="white-text">
              Meet the State of Maryland’s requirements for apprenticeship by
              participating in our state approved program.
            </h2>
          </div>
        </TwoColumnGrid>
        <Checklist>
          <li>24 College Credits Upon Graduation</li>
          <li>Earn While You Learn</li>
          <li>Convenient Training Locations</li>
          <li>Double Your Salary Over 4 Years</li>
        </Checklist>
        <h2 className="white-text">
          ABC offers the tools to build a career in the construction industry.
          Apprenticeship is a proven way to make it to the top.
        </h2>
        <br />
        <p className="white-text">
          ABC's apprenticeship training programs are fully approved and
          accredited by the National Center for Construction Education and
          Research (NCCER), U.S. Department of Labor, Maryland Apprenticeship
          and Training Council, and the Veterans Administration.
        </p>
      </InnerDiv>
      <InnerDiv>
        <TwoColumnGrid>
          <div>
            <h2>Employer Registration Materials</h2>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/matpempacceptagreement.pdf"
            >
              Employer Acceptance Agreement Form
            </a>
            <br />
            <a
              target="_blank"
              href="https://dev.abcbaltimore.org/wp-content/uploads/2024/08/Wage-Supplemental-Form-Updated-2024.docx"
            >
              Wage Form
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2019/08/ABC-Greater-Baltimore-Standards-2019-002.pdf"
            >
              Standards of Apprenticeship
            </a>
            <br />
          </div>
          <div>
            <h2>Curriculum</h2>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-Core.pdf"
            >
              CORE
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/E-Carpentry.pdf"
            >
              Carpentry
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-Electrical.pdf"
            >
              Electrical
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-HVAC.pdf"
            >
              HVACR
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-Plumbing.pdf"
            >
              Plumbing
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-Sheet-Metal.pdf"
            >
              Sheet Metal
            </a>
            <br />
            <a
              target="_blank"
              href="https://secure.abcbaltimore.org/wp-content/uploads/2015/11/C-Sprinkler-Fitting.pdf"
            >
              Sprinkler Fitting
            </a>
            <br />
          </div>
        </TwoColumnGrid>
        <br />
        <br />
        <h2 style={{ textAlign: "center" }}>Trades List</h2>
        <br />
        <strong>Carpentry</strong>
        <p>
          Carpenters are one of the most versatile construction occupations,
          with workers usually doing many different tasks.
        </p>
        <strong>Electrical</strong>
        <p>
          Electricians install and maintain electrical power, communications,
          lighting, and control systems in homes, businesses, and factories.
        </p>
        <strong>HVACR</strong>
        <p>
          HVAC mechanics and installers work on heating, ventilation, cooling,
          and refrigeration systems that control the temperature and air quality
          in buildings.
        </p>
        <strong>Plumbing</strong>
        <p>
          Plumbers install and repair pipes that carry liquids or gases to and
          in businesses, homes, and factories.
        </p>
        <strong>Sheet Metal</strong>
        <p>
          Sheet Metal workers fabricate or install products that are made from
          thin metal sheets, such as ducts used for heating and air
          conditioning.
        </p>
        <strong>Sprinkler Fitting</strong>
        <p>
          Sprinkler fitters develop procedures, design and install systems to
          prevent fires from destroying structures.
        </p>
        <br />
        <br />
        <h2>Additional Fields Include:</h2>
        <TwoColumnGrid>
          <div>
            <Checklist className="blue">
              <li>cement masonry</li>
              <li>drywall</li>
              <li>glazier</li>
              <li>heavy equipment operator</li>
              <li>laborer</li>
              <li>masonry</li>
              <li>roofing</li>
            </Checklist>
          </div>
          <div>
            <Checklist className="blue">
              <li>painting</li>
              <li>pipe insulator</li>
              <li>reinforced ironwork</li>
              <li>steamfitting</li>
              <li>structural ironwork</li>
              <li>welding</li>
            </Checklist>
          </div>
        </TwoColumnGrid>
        <h2>Important Links</h2>
        <br />
        <p>
          Main DLLR Website -{" "}
          <a target="_blank" href="https://www.dllr.state.md.us/">
            https://www.dllr.state.md.us/
          </a>
          <br />
          Licensing general website -{" "}
          <a target="_blank" href="https://www.dllr.state.md.us/license/">
            https://www.dllr.state.md.us/license/
          </a>
          <br />
          Electrical -{" "}
          <a href="https://www.dllr.state.md.us/license/elec/ ">
            https://www.dllr.state.md.us/license/elec/{" "}
          </a>
          <br />
          New Maryland Electricians Act -{" "}
          <a
            target="_blank"
            href="https://www.dllr.state.md.us/license/elec/elecnamech.shtml"
          >
            https://www.dllr.state.md.us/license/elec/elecnamech.shtml
          </a>
          <br />
          HVAC/Sheet Metal -{" "}
          <a target="_blank" href="https://www.dllr.state.md.us/license/hvacr/">
            https://www.dllr.state.md.us/license/hvacr/
          </a>
          <br />
          Plumbing -{" "}
          <a href="https://www.dllr.state.md.us/license/pl/">
            https://www.dllr.state.md.us/license/pl/
          </a>
          <br />
          PSI Licensing Exams -{" "}
          <a target="_blank" href="https://candidate.psiexams.com/">
            https://candidate.psiexams.com/
          </a>
          <br />
        </p>
        <h2>Speak with the Apprenticeship Team</h2>
        <br />
        <p>
          Contact:
          <br />
          <a target="_blank" href="mailto:apr@abcbaltimore.org">
            apr@abcbaltimore.org
          </a>
        </p>
      </InnerDiv>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default ApprenticeshipPage;
