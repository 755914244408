import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const CardRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  margin: 20px 0px;
  height: 350px;
  max-width: 350px;
  flex: 1 1 350px;
  background-color: var(--colors-site-mainTheme);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 80px 20px 20px 20px;
  text-align: center;
`;

export const Arrow = styled.div`
  width: 80px;
  height: 80px;
  font-size: 5em;
  color: white;
  border: 2px solid red;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  line-height: 1;
`;
