import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./technologyedpage.styles";

const TechnologyEdPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const baselineEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Baseline Basics")
  );
  const mteBasicsEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Basics Materials")
  );
  const mteAdvancedEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Advanced Materials")
  );
  const revuAdminEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Bluebeam for Administrative")
  );
  const readingDocumentsEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Bluebeam Reading")
  );
  const estimatingPrinciplesEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Bluebeam Construction Estimating")
  );

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Technology Education</h1>
        </div>
        <div>
          <p>
            Empowering construction professionals with essential technology
            skills, this training focuses on leveraging tools like Bluebeam Revu
            to enhance efficiency and collaboration. By mastering innovative
            solutions like Revu, individuals can drive productivity and stay
            ahead in an increasingly digital construction environment. This
            curriculum ensures professionals are equipped to meet the demands of
            modern construction with confidence and expertise.
          </p>
          <HashLink onClick={() => scrollToSection("baseline")}>
            Baseline Basics
          </HashLink>
          <HashLink onClick={() => scrollToSection("mte-basics")}>
            Materials Takeoffs & Estimates - Basics
          </HashLink>
          <HashLink onClick={() => scrollToSection("mte-advanced")}>
            Materials Takeoffs & Estimates - Advanced
          </HashLink>
          <HashLink onClick={() => scrollToSection("br-admin")}>
            Bluebeam Revu for Administrative Professionals
          </HashLink>
          <HashLink onClick={() => scrollToSection("reading-docs")}>
            Reading Documents
          </HashLink>
          <HashLink onClick={() => scrollToSection("principles")}>
            Construction Estimating Principles and Application
          </HashLink>
          <br />
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="baseline">
        <h3>Baseline Basics</h3>
        <p>
          Whether you're opening Bluebeam Revu for the first time, or you've
          used it for years, this class will ensure a higher baseline knowledge.
          Attendees will learn interface navigation including menu dropdowns,
          toolbars, various tool panels, keyboard shortcuts and a few
          recommended settings. While learning various markup and measurement
          tools, several tips & tricks will be shared to increase speed and
          accuracy. Learning Objectives:
        </p>
        <ul>
          <li>Interface Navigation</li>
          <li>Calibration & Measurements</li>
          <li>Introduction to the Markups List</li>
        </ul>
        {baselineEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={baselineEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${baselineEvents[0].start_date}`).format("MMM D @ h:mma")}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Baseline Basics"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {baselineEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/?tribe-bar-search=bluebeam+baseline"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="mte-basics">
        <h3>Material Takeoffs & Estimates - Basics</h3>
        <p>
          <em>*Mandatory Prerequisite - Baseline Basics</em>
          <br />
          With Revu's intuitive measurement tools and customizable interface, we
          can turn your highlighters into data rich takeoff tools. We'll start
          out going through the standard measurement tools and settings, then
          move into various customization options and discuss the results in the
          Markups List. Learning Objectives:
        </p>
        <ul>
          <li>Introduction to Viewports</li>
          <li>Measurement Tips & Tricks</li>
          <li>Custom Estimating Tools</li>
        </ul>
        {mteBasicsEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={mteBasicsEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${mteBasicsEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Material Takeoffs & Estimates - Basics"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {mteBasicsEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=bluebeam+basics+materials+takeoffs"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="mte-advanced">
        <h3>Material Takeoffs & Estimates - Advanced</h3>
        <p>
          <em>
            *Mandatory Prerequisite Baseline Basics & Material Takeoffs &
            Estimates - Basics
          </em>
          <br />
          After finishing the Bluebeam Takeoffs & Estimates - Basics course
          we'll move into creating custom columns, embedding formulas, and
          information organization. Did you know you can export to an Excel
          spreadsheet, or even link directly to that Excel template file full of
          formulas you've been using for years?
        </p>
        {mteAdvancedEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={mteAdvancedEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${mteAdvancedEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Material Takeoffs & Estimates - Advanced"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {mteAdvancedEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=bluebeam+advanced+materials+takeoffs"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="br-admin">
        <h3>Bluebeam Revu for Administrative Professionals</h3>
        <p>
          Administrative teams have unique document workflows that demand a
          different set of tools compared to design and construction teams. A
          typical Bluebeam class concentrates on design markups, estimating and
          collecting field data, but this class is very different! In just two
          hours you'll be ready to combine documents, mark for redaction, apply
          digital signatures and several other important administrative tasks.
          Learning Objectives:
        </p>
        {revuAdminEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={revuAdminEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${revuAdminEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Revu for Administrative Professionals"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {revuAdminEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=revu+administrative"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="reading-docs">
        <h3>Reading Construction Documents</h3>
        <p>
          This 2-part online program is for entry-level architectural or
          engineering designers, apprentices, entry-level tradespersons, project
          coordinators, assistant project managers, new project managers,
          estimators, specialty sales representatives, or anyone in career
          transition looking for a refresher.
          <br />
          Designed to quickly familiarize students with the basics of reading
          and understanding construction documents. From navigating title block
          information to combing through specification documents, participants
          will learn how to navigate a project and understand the material and
          construction requirements. Learning Objectives:
        </p>
        <ul>
          <li>Introduction to Construction Documents</li>
          <li>Understanding Industry Document Standards</li>
          <li>Drawing Navigation</li>
          <li>The Importance of Specialty Plans</li>
        </ul>
        {readingDocumentsEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={readingDocumentsEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${readingDocumentsEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Reading Construction Documents"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {readingDocumentsEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=reading+construction+documents"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="principles">
        <h3>Construction Estimating Principles and Application</h3>
        <p>
          Great Estimators know that to be competitive and competent in the
          current market, their companies must continually be in search of an
          edge. “How can we approach this project differently than our
          competition?” This virtual interactive training workshop is scheduled
          in one half-day session.
        </p>
        <p>
          Learning Objectives for Viewpoint 1 - The Estimating Principles Behind
          Every Bid:
        </p>
        <ul>
          <li>Cost/Price analysis</li>
          <li>Pricing project constraints</li>
          <li>How to build flexibility into your system</li>
          <li>Optimum time to complete</li>
          <li>Escalation</li>
          <li>Managing risk within the estimate</li>
          <li>Danger zones to avoid</li>
        </ul>
        <p>
          Learning Objectives for Viewpoint 2 - How Technology Can Produce
          Faster, More Accurate Estimates:
        </p>
        <ul>
          <li>Working with PDF drawings</li>
          <li>Calibrating drawings</li>
          <li>Measurement tools</li>
          <li>Custom measurement tools</li>
          <li>Displaying and exporting data</li>
        </ul>
        {estimatingPrinciplesEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={estimatingPrinciplesEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${estimatingPrinciplesEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Bluebeam Construction Estimating Principles & Application"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {estimatingPrinciplesEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=bluebeam+construction+principles"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default TechnologyEdPage;
