import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  max-height: 400px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const ClassNotice = styled.h3`
  color: red;
`;

export const Arrow = styled.div`
  width: 80px;
  height: 80px;
  font-size: 5em;
  color: white;
  border: 2px solid red;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  line-height: 1;
`;

export const Checklist = styled.ul`
  margin-top: 20px;

  li {
    color: white;
  }

  &.blue {
    li {
      color: var(--colors-site-mainTheme);
    }
  }
  li::marker {
    color: red;
  }
  line-height: 2;
  list-style-type: "\\02713${"  "}";
`;
