import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  HashLink,
} from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import { scrollToSection } from "../../utils/utility-functions";

import { Paragraph, Wrapper } from "./operationspage.styles";

const OperationsPage = ({ eventsCalendar }) => {
  console.log("EVENTS CALENDAR", eventsCalendar);
  const construction201Events = eventsCalendar?.filter((item) =>
    item.title.includes("Construction 101")
  );
  const microsoftprojectEvents = eventsCalendar?.filter((item) =>
    item.title.includes("Microsoft Project")
  );

  return (
    <Wrapper>
      <TitleBlock>
        <div>
          <h1 className="newSiteHeading">Operations</h1>
        </div>
        <div>
          <HashLink onClick={() => scrollToSection("construction-101")}>
            Construction 101/201
          </HashLink>
          <HashLink onClick={() => scrollToSection("microsoft-project")}>
            Introduction to Microsoft Project
          </HashLink>
          <br />
          <Link to="/management-education">
            <h3 className="orange">Other Management Classes</h3>
          </Link>
          <br />
        </div>
      </TitleBlock>
      <AccentLineMain />
      <Paragraph id="construction-101">
        <h3>Construction 101/201</h3>
        <p>
          Ever wish you knew more about the construction industry? How about
          your accountant, business development professional or your office
          manager - does their lack of first-hand experience hold them back from
          achieving all you hope them to? If so, this program is for you! This
          four-session course will help the participant gain a better
          understanding of the industry and how 'things work.' Learn key
          terminology, industry standards, size & diversity. Ideal for:
        </p>
        <ul>
          <li>Administrative Professionals</li>
          <li>Bookkeepers</li>
          <li>Assistant Project Managers</li>
          <li>Communications/Marketing Professionals</li>
          <li>Business Development/Sales Professionals</li>
        </ul>
        {construction201Events?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={construction201Events[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${construction201Events[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Construction 101/201 Course"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {construction201Events?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=%22Construction+101%22"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <Paragraph id="microsoft-project">
        <h3>Introduction to Microsoft Project</h3>
        <p>
          Managing time, tasks, people and resources effectively can make the
          difference between success and failure when completing a complex
          project. In this Introduction to Microsoft Project Getting Started
          class, students spend time getting comfortable with the MS Project
          user interface, including project views and the ribbon. They will also
          learn to enter, organize and link tasks, work with resources, and
          create projects independently. The course allows time to practice
          fundamental basic skills essential for efficient use of this program.
        </p>
        {microsoftprojectEvents?.length > 0 ? (
          <h3 className="orange">
            <Link target="_blank" to={microsoftprojectEvents[0].url}>
              Join Our Next Class{" "}
              {dayjs(`${microsoftprojectEvents[0].start_date}`).format(
                "MMM D @ h:mma"
              )}
            </Link>
          </h3>
        ) : (
          <h3>
            Email{" "}
            <Link
              target="_blank"
              to="mailto:bmattox@abcbaltimore.org?subject=Interest in Legal Seminars"
            >
              bmattox@abcbaltimore.org
            </Link>{" "}
            about Upcoming Classes
          </h3>
        )}
        <br />
        {microsoftprojectEvents?.length > 1 ? (
          <Link
            target="_blank"
            to="https://events.abcbaltimore.org/events/list/?tribe-bar-search=Microsoft+Project"
          >
            <CustomButton newSiteButton>Find More Courses</CustomButton>
          </Link>
        ) : null}
      </Paragraph>
      <FooterBlock>
        <h3 className="white-text">
          For more information on customized training, contact{" "}
          <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
            bchaapel@abcbaltimore.org
          </a>
        </h3>
      </FooterBlock>
    </Wrapper>
  );
};

export default OperationsPage;
