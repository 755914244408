import React from "react";
import { Link } from "react-router-dom";

import CustomButton from "../../components/custom-button/custom-button.component";

import { TitleBlock, FooterBlock } from "../../App.styles";
import { Wrapper, ImageBlock, Paragraph } from "./politicaladvocacypage.styles";

const PoliticalAdvocacyPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock
            src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/annapolis.jpg"
            alt="MD Statehouse"
          />
        </div>
        <div>
          <h1 className="newSiteHeading">Political Advocacy</h1>
          <h2>‘Get into politics or get out of business.’</h2>
          <p>
            Associated Builders and Contractors works nationally and locally to
            advocate for the merit shop industry. ABC Baltimore supports the
            “merit shop message” which is a philosophy that encourages open
            competition and free enterprise in the construction industry. Our
            chapter is involved locally, at the state level, and nationally, to
            protect and advance our members’ priorities. ABC Baltimore
            encourages its members to get involved with legislative, political
            and regulatory proposals that impact the merit shop construction
            industry. ABC Baltimore provides resources for members to better
            understand, support, and voice opinions on legislation.
          </p>
          <Link to="/grassroots">
            <CustomButton newSiteButton>Join Our Grassroots Team</CustomButton>
          </Link>
        </div>
      </TitleBlock>
      <Paragraph className="blue">
        <h2 className="white-text">State & Local Government</h2>
        <p style={{ width: "60%" }} className="white-text">
          ABC Baltimore is actively locally and in the statehouse where we
          advocate for legislation that promotes and advances free enterprise
          and open competition. The 4 Maryland chapters, members, staff and
          lobbying team, work together on a Joint Legislative Committee during
          Maryland’s Legislative Session to prioritize and discuss legislation.
        </p>
        <a
          href="https://www.abc.org/Politics-Policy/ABC-Action-App/Action-Center"
          target="_blank"
        >
          <CustomButton newSiteButton>ABC Action Center</CustomButton>
        </a>
        <br />
        <br />
        <a href="http://webuildmaryland.org/" target="_blank">
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2021/12/wbbanner2-copy.jpg"
            alt="We Build Maryland magazine banner"
          />
        </a>
      </Paragraph>
      <Paragraph>
        <h2>Federal Government</h2>
        <p>
          ABC National is one of the most influential business advocacy groups
          in the capitol and its core purpose is to defend free enterprise in
          the construction industry.
        </p>
        <h2>National Political Action Committee</h2>
        <p>
          ABC’s PAC is your voice in elective politics. ABC PAC members’
          contributions are combined with others to jointly support candidates.
          The purpose of ABC PAC is to work within the federal election process
          to further the goals of the association through direct involvement in
          supporting and opposing candidates for the Office of President, United
          States Senate and United States House of Representatives. This
          provides a unified and powerful voice for the merit agenda. It is also
          an opportunity to participate in one of the largest pro-business PACs
          in the nation. The Free Enterprise Alliance is the action arm for
          small businesses, entrepreneurs and other advocates of limited
          government, open and fair competition, created by ABC National.
          Contributions to FEA are used to support campaigns or legislation that
          benefit the merit shop industry.
        </p>
      </Paragraph>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default PoliticalAdvocacyPage;
