import React from "react";

import {
  ImageContainer,
  SponsorContainer,
  Wrapper,
} from "./sponsorLogoList.styles";

const proSponsorData = require("../../static/pro_sponsor_logo_data.js");

const SponsorLogoList = () => {
  return (
    <Wrapper>
      <h3>Elite Sponsors</h3>
      <SponsorContainer>
        {proSponsorData.default.map((item) => {
          const { id, companyName, companyUrl, sponsorLevel, image } = item;
          return (
            sponsorLevel === "elite" && (
              <ImageContainer key={`${Math.random()}-${id}`}>
                <a target="_blank" href={companyUrl}>
                  <img alt={companyName} src={image} />
                </a>
              </ImageContainer>
            )
          );
        })}
      </SponsorContainer>
      <h3>Executive Sponsors</h3>
      <SponsorContainer>
        {proSponsorData.default.map((item) => {
          const { id, companyName, companyUrl, sponsorLevel, image } = item;
          return (
            sponsorLevel === "executive" && (
              <ImageContainer key={`${Math.random()}-${id}`}>
                <a target="_blank" href={companyUrl}>
                  <img alt={companyName} src={image} />
                </a>
              </ImageContainer>
            )
          );
        })}
      </SponsorContainer>
      <h3>Corporate Sponsors</h3>
      <SponsorContainer>
        {proSponsorData.default.map((item) => {
          const { id, companyName, companyUrl, sponsorLevel, image } = item;
          return (
            sponsorLevel === "corporate" && (
              <ImageContainer key={`${Math.random()}-${id}`}>
                <a target="_blank" href={companyUrl}>
                  <img alt={companyName} src={image} />
                </a>
              </ImageContainer>
            )
          );
        })}
      </SponsorContainer>
      <h3>Professional Sponsors</h3>
      <SponsorContainer>
        {proSponsorData.default.map((item) => {
          const { id, companyName, companyUrl, sponsorLevel, image } = item;
          return (
            sponsorLevel === "professional" && (
              <ImageContainer key={`${Math.random()}-${id}`}>
                <a target="_blank" href={companyUrl}>
                  <img alt={companyName} src={image} />
                </a>
              </ImageContainer>
            )
          );
        })}
      </SponsorContainer>
    </Wrapper>
  );
};

export default SponsorLogoList;
