const staffList = [
  {
    id: 48,
    Name: "Ambre Schaffer",
    Title: "Membership Director",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/Ambre.jpg"),
    email: "aschaffer@abcbaltimore.org",
  },
  {
    id: 46,
    Name: "Bonnie Mattox",
    Title: "Education Programs Coordinator",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/bonnie.jpg"),
    email: "bmattox@abcbaltimore.org",
  },
  {
    id: 33,
    Name: "Brittany Chaapel",
    Title: "Director of Programs",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/brittany.jpg"),
    email: "bchaapel@abcbaltimore.org",
  },
  {
    id: 45,
    Name: "Bruce Talbot",
    Title: "ABC GIMA",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/bruce.jpg"),
    email: "bruce@abcgima.org",
  },
  {
    id: 38,
    Name: "Christopher Hadfield",
    Title: "Vice President, Workforce Development",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/chris.jpg"),
    email: "chadfield@abcbaltimore.org",
  },
  {
    id: 42,
    Name: "Donte Lee",
    Title: "Director of Education",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/donte.jpg"),
    email: "dlee@abcbaltimore.org",
  },
  {
    id: 47,
    Name: "Faith Malashuk",
    Title: "Compliance and Administration Supervisor",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/faith.jpg"),
    email: "fmalashuk@abcbaltimore.org",
  },
  {
    id: 34,
    Name: "Gene Cobb",
    Title: "Safety Director",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/gene.jpg"),
    email: "gcobb@abcbaltimore.org",
  },
  {
    id: 44,
    Name: "Jimmy Stewart",
    Title: "Project JumpStart, Executive Director",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/jimmy.jpg"),
    email: "jstewart@abcbaltimore.org",
  },
  {
    id: 30,
    Name: "Lauren Blumberg",
    Title: "Director of Finance",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/lauren.jpg"),
    email: "lblumberg@abcbaltimore.org",
  },
  {
    id: 47,
    Name: "Mandi Pusateri",
    Title: "Director of Operations",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/mandi.jpg"),
    email: "mpusateri@abcbaltimore.org",
  },
  {
    id: 39,
    Name: "Mike Henderson",
    Title: "President",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/mike.jpg"),
    email: "mhenderson@abcbaltimore.org",
  },
  {
    id: 40,
    Name: "Rachel Hughes",
    Title: "Member Services",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/rachel.jpg"),
    email: "rhughes@abcbaltimore.org",
  },
  {
    id: 31,
    Name: "Sharon Burley",
    Title: "Assistant Manager of Workforce Development",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/sharon.jpg"),
    email: "sburley@abcbaltimore.org",
  },
  {
    id: 35,
    Name: "Siona Glasgow",
    Title: "Apprenticeship Coordinator",
    Description: "Lorem Ipsum",
    Image: require("../static/images/Staff/siona.jpg"),
    email: "sglasgow@abcbaltimore.org",
  },
];

export default staffList;
