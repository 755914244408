import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  FooterBlock,
  AccentLineMain,
  InnerDiv,
} from "../../App.styles";
import CustomButton from "../../components/custom-button/custom-button.component";
import {
  Wrapper,
  ImageBlock,
  VideoBlock,
  IframeContainer,
  ImageCard,
  Image,
} from "./membershippage.styles";

const MembershipPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <IframeContainer>
          <VideoBlock
            src="https://player.vimeo.com/video/242927031"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="Management.mp4"
          />
        </IframeContainer>
        <div>
          <h1 className="newSiteHeading">Membership</h1>
          <p>
            Contact us today to learn about how ABC can help{" "}
            <strong>
              connect your company to opportunity, gain a competitive edge and
              protect your interests.
            </strong>
          </p>
          <Link to="/membership-interest">
            <CustomButton newSiteButton>Join ABC</CustomButton>
          </Link>
        </div>
      </TitleBlock>
      <AccentLineMain />
      <br />
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/11391306_10153334235598604_492053712487354792_n.jpg" />
        </div>
        <div>
          <h2>
            Find out why ABC Baltimore is Maryland's largest trade association
            for commercial builders.
          </h2>
          <p>
            ABC helps its members develop people, win work and deliver that work
            safely, ethically and profitably for the betterment of the
            communities in which ABC and its members work.
          </p>
          <p>
            ABC members cover the entire spectrum of the construction team, from
            general contractors and construction managers, to architects and
            engineers to trade contractors of every conceivable specialty.
          </p>
          <Link
            target="_blank"
            to="https://dev.abcbaltimore.org/wp-content/uploads/2025/01/Member-Guide_2025.pdf"
          >
            <CustomButton newSiteButton>ABC Member Guide</CustomButton>
          </Link>
        </div>
      </TitleBlock>
      <InnerDiv>
        <h2>Our Value Promise: CONNECT PROTECT AND COMPETE</h2>
        <br />
        <strong>ABC WILL CONNECT YOU TO OPPORTUNITY THROUGH:</strong>
        <ul>
          <li>Our monthly events and networks</li>
          <li>Our Peer groups and committees</li>
          <li>Our annual awards program</li>
        </ul>
        <br />
        <strong>ABC GIVES ITS MEMBERS A COMPETITIVE ADVANTAGE THROUGH:</strong>
        <ul>
          <li>Safety training</li>
          <li>Apprenticeship</li>
          <li>Our member-only workers comp; P&C and retirement plans</li>
          <li>Management and Operations Training</li>
          <li>
            Job-ready entry level workers through Project JumpStart and our
            day-time trade school
          </li>
        </ul>
        <br />
        <strong>ABC WILL PROTECT YOUR INTERESTS THROUGH:</strong>
        <ul>
          <li>
            Our in-house subject matter experts on everything from regulations;
            taxes, HR; legal and more.
          </li>
          <li>Political advocacy: local, state and national</li>
        </ul>
      </InnerDiv>
      <ImageCard className="blue">
        <Image>
          <img
            src="https://dev.abcbaltimore.org/wp-content/uploads/2023/04/Ambassador-Logo-2048x2048.jpg"
            alt="ABC Ambassador Committee Logo"
          />
        </Image>
        <div>
          <h2 className="white-text">The Ambassador Committee</h2>
          <br />
          <p>
            Focused on helping members realize the full value and potential of
            their ABC membership
            <br />
            <br />
            Chair: Jeanne Schenk, Perry Moving and Storage
            <br />
            <br />
            Staff Liaison:{" "}
            <a target="_blank" href="mailto:aschaffer@abcbaltimore.org">
              Ambre Schaffer
            </a>
          </p>
        </div>
      </ImageCard>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default MembershipPage;
