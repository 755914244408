import React, { useState } from "react";
import { useForm, Controller, set } from "react-hook-form";

import Page from "../../components/page/page.component";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import Spinner from "../../components/spinner/spinner.component";
import { TitleBlock } from "../../App.styles";

import {
  Wrapper,
  FormWrapper,
  ErrorMessage,
  InputLabel,
  SuccessMessage,
  TextArea,
  GlassWrap,
  ImageBlock,
  FormTitle,
  SectionTitle,
  Tryptych,
  TryptychImage,
} from "./facilityrentalpage.styles";

const FacilityRentalPage = ({ data }) => {
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      input_1_3: "",
      input_3: "",
      input_4: "",
      input_5_1: "",
      input_5_3: "",
      input_5_4: "",
      input_5_5: "",
      input_8: "",
      input_9: "",
      input_10: "",
      input_11: "",
      input_12: "",
    },
  });

  const onSubmit = (data) => {
    setSuccess();
    setProcessing(true);
    const formElement = document.forms["facilityRentalForm"],
      { action, method } = formElement,
      formData = new FormData(formElement);

    for (var value of formData.entries()) {
      console.log("FACILITY FORM VALUE", value);
    }

    const options = {
      method,
      body: formData,
    };

    fetch(action, options)
      .then((response) => response.json())
      .then((response) => {
        setProcessing(false);
        // console.log(response)

        if (!response.is_valid) {
          setSuccess(false);
        } else {
          setSuccess(true);
        }
      })
      .catch((error) => {});
  };

  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2021/11/CEA-NEWEST-1024x684.jpg" />
        </div>
        <div>
          <h1 className="newSiteHeading">Facility Rental</h1>
          <p>
            ABC's new State-of-the-Art training facility and classrooms are
            available for your next meeting class or event.
          </p>
          <h2>Construction Education Academy Features</h2>
          <ul>
            <li>11 classrooms with seating capacities that range from 18-40</li>
            <li>One large meeting space with seating capacity up to 100</li>
            <li>6 fully outfitted shops for hands on training</li>
          </ul>
          <strong>Additional Features:</strong>
          <ul>
            <li>Free onsite parking</li>
            <li>Each room is fully equipped for audio-visual presentations</li>
          </ul>
          <p>
            Classroom Rental Pricing: (Half Day/Full Day)
            <br />
            $250/$500 for members.
            <br />
            $450/$750 for non-members.
            <br />
            <br />
            Contact{" "}
            <a href="mailto:bmattox@abcbaltimore.org?subject=Interest in Facility Rental">
              bmattox@abcbaltimore.org
            </a>{" "}
            today to secure space or to get additional details.
          </p>
        </div>
      </TitleBlock>
      <Tryptych>
        <TryptychImage
          tryptychImage="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/2020-11-05_10-48-49-scaled-1-768x576.jpeg"
          aria-label="HVAC facility room"
        >
          <div>
            <h2 className="white-text">HVAC</h2>
          </div>
        </TryptychImage>
        <TryptychImage
          tryptychImage="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/2020-11-05_10-43-15-scaled-1-768x576.jpeg"
          aria-label="SPrinkler Fitting facility Room"
        >
          <div>
            <h2 className="white-text">Sprinkler Fitting</h2>
          </div>
        </TryptychImage>
        <TryptychImage
          tryptychImage="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/virtual-simulators-768x576.jpeg"
          aria-label="Man standing near Equipment Operator Simulator"
        >
          <div>
            <h2 className="white-text">Operator Simulator</h2>
          </div>
        </TryptychImage>
      </Tryptych>
      <Tryptych>
        <TryptychImage
          tryptychImage="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/2020-11-05_10-41-39-scaled-1-768x576.jpeg"
          aria-label="Plumbing facility room"
        >
          <div>
            <h2 className="white-text">Plumbing</h2>
          </div>
        </TryptychImage>
        <TryptychImage
          tryptychImage="https://dev.abcbaltimore.org/wp-content/uploads/2022/03/2020-11-05_10-43-44-scaled-1-768x576.jpeg
          "
          aria-label="Electrical facility Room"
        >
          <div>
            <h2 className="white-text">Sprinkler Fitting</h2>
          </div>
        </TryptychImage>
        <TryptychImage
          tryptychImage="	https://dev.abcbaltimore.org/wp-content/uploads/2022/03/2020-11-05_10-45-37-scaled-1-768x576.jpeg"
          aria-label="Sheet Metal facility Room"
        >
          <div>
            <h2 className="white-text">Sheet Metal</h2>
          </div>
        </TryptychImage>
      </Tryptych>
      <div
        style={{
          justifySelf: "start",
          paddingLeft: "10%",
          marginTop: "50px",
          backgroundColor: "var(--colors-site-mainTheme)",
          paddingTop: "50px",
          paddingBottom: "50px",
          width: "100%",
        }}
      >
        <h2 className="white-text">Task Training</h2>
        <p className="white-text">
          Use our fully-equipped hands-on shops to help increase the level of
          your craftworkers. Hands-on training shops available for task-training
          in:
          <ul>
            <li>Plumbing</li>
            <li>HVACR</li>
            <li>Carpentry</li>
            <li>Electrical</li>
            <li>Equipment Operator</li>
            <li>Sheet Metal</li>
            <li>Pipefitting</li>
            <li>Sprinkler Fitting</li>
            <li>Welding (to come)</li>
          </ul>
          For more information on task training at the Construction Education
          Academy, contact{" "}
          <a href="mailto:chadfield@abcbaltimore.org">
            chadfield@abcbaltimore.org
          </a>
        </p>
      </div>
      <GlassWrap>
        {success ? (
          <SuccessMessage>
            Thank you for your Submission. Someone will contact you soon.
          </SuccessMessage>
        ) : (
          <FormWrapper>
            <FormTitle>Facility Rental Application</FormTitle>
            <SectionTitle>Company Details</SectionTitle>
            <hr></hr>
            <form
              id="facilityRentalForm"
              onSubmit={handleSubmit(onSubmit)}
              action="https://dev.abcbaltimore.org/wp-json/gf/v2/forms/12/submissions"
              method="post"
            >
              <Controller
                control={control}
                name="input_1_3"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*Name" />}
              />
              {errors.input_1_3?.type === "required" && (
                <ErrorMessage>"Name is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_3"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput {...field} label="*Company" />
                )}
              />
              {errors.input_3?.type === "required" && (
                <ErrorMessage>"Company is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_4"
                rules={{
                  required: true,
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Email Address" />
                )}
              />
              {errors.input_4?.type === "required" && (
                <ErrorMessage>"Email is required"</ErrorMessage>
              )}
              {errors.input_4?.type === "pattern" && (
                <ErrorMessage>
                  "Please Enter a Valid Email Address"
                </ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5_1"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput {...field} label="*Street Address" />
                )}
              />
              {errors.input_5_1?.type === "required" && (
                <ErrorMessage>"Street Address is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5_3"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*City" />}
              />
              {errors.input_5_3?.type === "required" && (
                <ErrorMessage>"City is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5_4"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*State" />}
              />
              {errors.input_5_4?.type === "required" && (
                <ErrorMessage>"State is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5_5"
                rules={{
                  required: true,
                  pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Zipcode" />
                )}
              />
              {errors.input_5_5?.type === "required" && (
                <ErrorMessage>"Zipcode is Required"</ErrorMessage>
              )}
              {errors.input_5_5?.type === "pattern" && (
                <ErrorMessage>"Please Enter a Valid Zipcode"</ErrorMessage>
              )}

              <SectionTitle>Event Details</SectionTitle>
              <hr></hr>
              <InputLabel>Event Date</InputLabel>
              <br />
              <br />
              <input {...register("input_8", { required: true })} type="date" />
              <br />
              <br />
              {errors.input_8?.type === "required" && (
                <ErrorMessage>"Date is Required"</ErrorMessage>
              )}

              <InputLabel>Expected Start Time</InputLabel>
              <br />
              <br />
              <input {...register("input_9", { required: true })} type="time" />
              <br />
              <br />
              {errors.input_9?.type === "required" && (
                <ErrorMessage>"Start Time is Required"</ErrorMessage>
              )}

              <InputLabel>Expected End Time</InputLabel>
              <br />
              <br />
              <input
                {...register("input_10", { required: true })}
                type="time"
              />
              <br />
              <br />
              {errors.input_10?.type === "required" && (
                <ErrorMessage>"End Time is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_11"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput {...field} label="*Expected Number of People" />
                )}
              />
              {errors.input_11?.type === "required" && (
                <ErrorMessage>"Number of People is Required"</ErrorMessage>
              )}

              <InputLabel htmlFor="input_12">
                *Briefly Describe Your Event
              </InputLabel>
              <TextArea
                {...register("input_12", { required: true, maxLength: 500 })}
              />
              {errors.input_12?.type === "required" && (
                <ErrorMessage>"Event Description is Required"</ErrorMessage>
              )}
              <br />

              <br />
              <CustomButton>Submit Application</CustomButton>
              <br />
              <br />
              {isSubmitted && isSubmitSuccessful === false ? (
                <ErrorMessage>Please check the form for errors</ErrorMessage>
              ) : null}
            </form>
            {processing ? <Spinner /> : null}
            {success === false ? (
              <ErrorMessage>
                There was an error processing your information. Please try again
                or contact ABC for help.
              </ErrorMessage>
            ) : null}
          </FormWrapper>
        )}
      </GlassWrap>
    </Wrapper>
  );
};

export default FacilityRentalPage;
