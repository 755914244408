import React, { useEffect, useRef, useState } from "react";
import { CCarousel, CCarouselItem } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { myunescape } from "../../utils/utility-functions";

import LogoContainer from "../../components/logo-container/logo-container.component";
import MobileMenu from "../../components/mobile-menu/mobile-menu.component";

import { menu } from "../../components/side-menu/menuFile";

import Opener from "../../static/videos/Website Video Wide_1.mp4";
import info1 from "../../static/images/IG1.gif";
import info2 from "../../static/images/IG2.gif";
import info3 from "../../static/images/IG3.gif";
import needTraining from "../../static/images/Need_Training.jpg";
import expandNetwork from "../../static/images/Expand_Network.jpg";
import joinABC from "../../static/images/Join_ABC.jpg";
import saveMoney from "../../static/images/Save_Money.jpg";
import logoWhite from "../../static/images/abc_logo_white.svg";

import {
  AccentLine,
  AccentLineSmall,
  BottomHero,
  BottomTag,
  CarouselButton,
  CarouselContent,
  CarouselDiv,
  CarouselImage,
  CarouselItemDiv,
  CarouselText,
  ColumnDiv,
  ImageBackground,
  InfoWrapper,
  InfographicContainer,
  InfoColumnLeft,
  InfoColumnRight,
  ImageDiv,
  LeftColumn,
  LogoOverlay,
  LogoOverlayTop,
  PictureDiv,
  ValueParagraph,
  ValuesDiv,
  Video,
  VideoDiv,
} from "./homepage2.styles";
import SponsorLogoList from "../../components/sponsorLogoList/sponsorLogoList.component";

const HomePage2 = ({
  eventsCalendar,
  intViewportWidth,
  token,
  setToken,
  setHamburgerToggle,
}) => {
  const [eventItems, setEventItems] = useState([]);
  const videoRef = useRef(null);

  const mappedEventList = () => {
    const list = eventsCalendar.map((items) => {
      if (items.image) {
        return (
          <CCarouselItem key={`$Math.Random()$items.start_date`}>
            <CarouselItemDiv imageUrl="" pause="true">
              <CarouselImage src={items.image.url} />
              <CarouselContent>
                <CarouselText>{myunescape(items.title)}</CarouselText>
                <CarouselText>
                  {dayjs(`${items.start_date}`)
                    .format("MMMM D, YYYY")
                    .toUpperCase()}
                </CarouselText>
                <a href={items.url}>
                  <CarouselButton>Get Tickets</CarouselButton>
                </a>
              </CarouselContent>
            </CarouselItemDiv>
          </CCarouselItem>
        );
      }
    });
    // console.log("list", list);
    setEventItems(list.filter((el) => el !== undefined).slice(0, 4));
    return list.filter((el) => el !== undefined).slice(0, 4);
  };

  useEffect(() => {
    console.log("EFFECTS");
    if (eventsCalendar.length > 0) {
      // console.log("EVENTS CALENDAR", eventsCalendar);
      mappedEventList();
    }
  }, [eventsCalendar]);

  useEffect(() => {
    const playVideo = videoRef.current;
    if (playVideo) {
      playVideo.play();
    }
  }, []);

  return (
    <>
      {/* video playback div */}
      {intViewportWidth < 1101 ? null : (
        <VideoDiv>
          <Video ref={videoRef} id="ABCOpener" autoplay loop muted poster="">
            <source src={Opener} type="video/mp4" />
            Your Browser does not support the video tag
          </Video>
          {/* <MainTagline>
          Together, WE ARE rebuilding a greater Baltimore, one project, one
          career, at a time.
        </MainTagline> */}
          <LogoOverlay>
            {intViewportWidth > 1100 ? (
              <LogoContainer
                source={logoWhite}
                alt="ABC Logo"
                widthValue="150px"
                heightValue="auto"
                link="/"
              />
            ) : null}
            {intViewportWidth < 1100 ? (
              <LogoContainer
                source={logoWhite}
                alt="ABC Logo"
                widthValue="150px"
                heightValue="auto"
                link="/"
              />
            ) : null}
          </LogoOverlay>
          {/* <LogoOverlayTop>
            <p className="white-anchor">
              <a
                target="_blank"
                href="https://secure.abcbaltimore.org/2024-in-review/"
              >
                <strong>2024 A Year in Words and Images</strong>
              </a>
            </p>
          </LogoOverlayTop> */}
        </VideoDiv>
      )}
      <MobileMenu
        token={token}
        setToken={setToken}
        setHamburgerToggle={setHamburgerToggle}
        menu={menu}
      />
      <CarouselDiv>
        {eventItems && eventItems.length > 0 ? (
          <CCarousel controls dark>
            <CCarouselItem>
              <CarouselItemDiv imageUrl="" pause="true">
                <CarouselImage src="https://events.abcbaltimore.org/wp-content/uploads/2024/06/Bluebeam_Tech_Wide.png" />
                <CarouselContent>
                  <CarouselText>BLUEBEAM and Procore Training</CarouselText>
                  <CarouselText>virtual and in-person</CarouselText>
                  <Link to="/technology-training">
                    <CarouselButton>See Class Schedule</CarouselButton>
                  </Link>
                </CarouselContent>
              </CarouselItemDiv>
            </CCarouselItem>
            {eventItems}
            <CCarouselItem>
              <CarouselItemDiv imageUrl="" pause="true">
                <CarouselImage src="https://events.abcbaltimore.org/wp-content/uploads/2024/06/Blueprint_Reading.png" />
                <CarouselContent>
                  <CarouselText>
                    Blueprint Reading Classes at
                    <br />
                    ABC Greater Baltimore
                  </CarouselText>
                  <CarouselText>
                    From Basic to trade specific
                    <br />
                    (In English and Spanish)
                  </CarouselText>
                  <Link to="/blueprint-reading">
                    <CarouselButton>Find Classes</CarouselButton>
                  </Link>
                </CarouselContent>
              </CarouselItemDiv>
            </CCarouselItem>
            <CCarouselItem>
              <CarouselItemDiv imageUrl="" pause="true">
                <CarouselImage src="https://events.abcbaltimore.org/wp-content/uploads/2024/06/Safety_Training.png"></CarouselImage>
                <CarouselContent>
                  <CarouselText>
                    Scheduled OSHA and
                    <br />
                    First-Aid training
                  </CarouselText>
                  <CarouselText>
                    (In English and Spanish)
                    <br />
                    ASK ABOUT CUSTOMIZED TRAINING
                  </CarouselText>
                  <a href="https://events.abcbaltimore.org">
                    <CarouselButton>Safety Training</CarouselButton>
                  </a>
                </CarouselContent>
              </CarouselItemDiv>
            </CCarouselItem>
          </CCarousel>
        ) : null}
      </CarouselDiv>
      {/* picture grid div  */}
      <PictureDiv>
        <h2>
          With over 650 Member Firms
          <br />
          WE ARE the Face of Construction in Baltimore
        </h2>
        <AccentLine className="right" />
        <ImageDiv>
          <Link to="/apprenticeship">
            <ImageBackground imageUrl={needTraining}>
              <div>
                <h3>Need Training</h3>
              </div>
            </ImageBackground>
          </Link>
          <Link to="/events">
            <ImageBackground imageUrl={expandNetwork}>
              <div>
                <h3>Expand Your Network</h3>
              </div>
            </ImageBackground>
          </Link>
          <Link to="save-money">
            <ImageBackground imageUrl={saveMoney}>
              <div>
                <h3>Save Money</h3>
              </div>
            </ImageBackground>
          </Link>
          <Link to="membership">
            <ImageBackground imageUrl={joinABC}>
              <div>
                <h3>Join ABC</h3>
              </div>
            </ImageBackground>
          </Link>
        </ImageDiv>
      </PictureDiv>
      {/* who we are div */}
      <ValuesDiv>
        <h2>WE ARE ABC Baltimore</h2>
        <AccentLine className="left" />
        <ColumnDiv>
          <LeftColumn>
            <ValueParagraph>
              <AccentLineSmall />
              <h2>WHO WE ARE</h2>
            </ValueParagraph>
            <p>
              Associated Builders and Contractors of Greater Baltimore is the
              largest organization in Maryland to represent the commercial
              construction industry. Our 650 members represent the leading
              general contractors and specialty contractors in the region.
            </p>
            <ValueParagraph>
              <AccentLineSmall />
              <h2>OUR MISSION</h2>
            </ValueParagraph>
            <p>
              ABC helps its members develop people, win work and deliver that
              work safely, ethically and profitably for the betterment of the
              communities in which ABC and its members work. ABC members cover
              the entire spectrum of the construction team, from general
              contractors and construction managers, to architects and engineers
              to trade contractors of every conceivable specialty.
            </p>
            <ValueParagraph>
              <AccentLineSmall />
              <h2>OUR VALUE PROMISE</h2>
            </ValueParagraph>
            <p>
              ABC Greater Baltimore will CONNECT its members to opportunity;
              provide a COMPETITIVE advantage and PROTECT their interests.
            </p>
          </LeftColumn>
        </ColumnDiv>
      </ValuesDiv>
      {/* gifs */}
      <InfoWrapper>
        <h2>ABC by the Numbers</h2>
        <AccentLine className="right" />
        <InfographicContainer>
          <InfoColumnLeft>
            <img
              src={info1}
              alt="ABC member total 640"
              width="45%"
              height="57%"
            />
          </InfoColumnLeft>
          <InfoColumnRight>
            <img src={info2} alt="ABC member total 640" width="55%" />

            <img src={info3} alt="ABC member total 640" width="65%" />
          </InfoColumnRight>
        </InfographicContainer>
      </InfoWrapper>
      {/* Pro Sponsor Logos */}
      <ValuesDiv>
        <h2>ABC PRO Sponsors</h2>
        <AccentLine />
      </ValuesDiv>
      <SponsorLogoList />
      {/* bottom hero */}
      <BottomHero>
        <BottomTag>
          <h2>
            ABC Greater Baltimore
            <br />
            2101 E. Biddle St. Suite 5000
            <br />
            Baltimore, MD 21213
            <br />
            410.821.0351
          </h2>
        </BottomTag>
      </BottomHero>
    </>
  );
};

export default HomePage2;
